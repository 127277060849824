import React from 'react';
import { CustomModal, CustomTransComponent } from '../../../components';
import { useConfirm } from '@fiji/common/src/hooks';
import { ModalActions } from '../../../components/CustomModal';
import {
    FormControlLabel,
    FormHelperText,
    List,
    ListItem,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { WIDGET_DESCRIPTION_LIMIT, WIDGET_NAME_LIMIT } from '@fiji/common/src/constants';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { resetCloneWidgetModal, setCloneWidgetPayload } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useHandleWidgetListActions } from '../common';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
export const CloneWidgetModal = React.forwardRef((props: any, ref): JSX.Element => {
    const dispatch = useAppDispatch();

    const {
        isLoading,
        payload,
        handleCloneModalAction: handleModalAction,
        handleCloneSave: handleSave,
    } = useHandleWidgetListActions('cloneWidgetModal', (args: any): void => onClick(args));

    React.useImperativeHandle(
        ref,
        () => ({
            handleModalAction: (action: boolean, widgetData: any): void => handleModalAction(action, widgetData),
        }),
        []
    );
    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(
        (): Promise<void> => handleSave(props?.modalType, onCancel)
    );

    const permissions = useTypedSelector(selectCurrentPermission);

    const { hasPermission } = useRBAC(permissions);
    const canCreateSystemWidgets = hasPermission('create-system-widgets');
    const canCreateUserWidgets = hasPermission('create-user-widgets');

    const newWidgetModalActions: ModalActions[] = [
        {
            key: '#creationcancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
                resetCloneWidgetModal();
            },
        },
        {
            key: '#creationsave',
            label: <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />,
            variant: 'contained',
            disabled: !payload?.name || isLoading,
            handleClick: (): void => {
                onConfirm();
            },
        },
    ];

    return (
        <CustomModal
            key="#new!@1313d"
            type="primary"
            isLoading={isLoading}
            actionsDivider
            header={
                props?.modalType === 'save' ? (
                    <CustomTransComponent translationKey={'WIDGETS:SAVE_AS_LABEL'} />
                ) : (
                    <CustomTransComponent translationKey={'WIDGETS:CLONE_WIDGET'} />
                )
            }
            actions={newWidgetModalActions}
            isOpen={isVisible}
            onClose={onCancel}
        >
            <Typography variant="subtitle1" fontWeight={'600'} my={2}>
                <CustomTransComponent translationKey={'WIDGETS:CLONE_MODAL_HEADER'} />
            </Typography>
            <TextField
                id="clone-name"
                fullWidth
                name="name"
                label={<CustomTransComponent translationKey={'COMMON:NAME'} />}
                inputProps={{ maxLength: WIDGET_NAME_LIMIT }}
                value={payload?.name ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(setCloneWidgetPayload({ key: 'name', value: e.target.value }));
                }}
                variant="filled"
                className="margin-top-18"
                required
            />
            <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                <FormHelperText>
                    <CustomTransComponent translationKey={'COMMON:REQUIRED_LABEL'} />
                </FormHelperText>
                <FormHelperText> {`${payload?.name?.length ?? 0}/${WIDGET_NAME_LIMIT}`}</FormHelperText>
            </Stack>
            <TextField
                id="outlined-password-input"
                fullWidth
                variant="filled"
                name="description"
                inputProps={{ maxLength: WIDGET_DESCRIPTION_LIMIT }}
                value={payload?.description ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(setCloneWidgetPayload({ key: 'description', value: e.target.value }));
                }}
                label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                className="margin-top-18"
            />
            <FormHelperText className="text-align-end padding-x-16">
                {`${payload?.description?.length ?? 0}/${WIDGET_DESCRIPTION_LIMIT}`}
            </FormHelperText>

            {canCreateSystemWidgets && canCreateUserWidgets && (
                <>
                    <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'} mt={'18px'}>
                        Is this a System or User widget?
                    </Typography>
                    <List>
                        <RadioGroup
                            id="creationmodalradio"
                            value={payload?.isSystemWidget ?? false}
                            onChange={(e: any): any => {
                                dispatch(
                                    setCloneWidgetPayload({
                                        key: 'isSystemWidget',
                                        value: Boolean(e.target.value !== 'false'),
                                    })
                                );
                            }}
                        >
                            <ListItem>
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    id="system"
                                    label={
                                        <Typography variant="body1">
                                            <span className="f-16 fw-600 black-500">System Widget; </span> Displayed to
                                            all users.
                                        </Typography>
                                    }
                                />
                            </ListItem>

                            <ListItem>
                                <FormControlLabel
                                    value={false}
                                    id="user"
                                    control={<Radio />}
                                    label={
                                        <Typography variant="body1">
                                            <span className="f-16 fw-600 black-500">User Widget; </span> Private,
                                            displayed only to you.
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </RadioGroup>
                    </List>
                </>
            )}
        </CustomModal>
    );
});
