import React from 'react';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useTypedSelector } from '@fiji/common';
import { useGetDocumentsByFilterMutation } from '@fiji/common/src/features/documents/documentApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllMonthlyReportsQuery } from '@fiji/common/src/features/reports/reportApi';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List, Stack, Typography } from '@mui/material';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterList from '@mui/icons-material/FilterList';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useDebounce, useIsMount } from 'hooks';
import { DocumentFilter } from './DocumentFilter';
import { ReportsFilter } from 'pages/Reports';
import { Loader } from 'components';
import { Download } from '@mui/icons-material';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';

export const DocumentReportList = (): JSX.Element => {
    const { categoryId, type, typeId }: any = useParams();
    const { state } = useLocation();
    const theme = useTheme();
    const currentOrg = useTypedSelector(selectedOrg);
    const [searchKey, setSearchKey] = React.useState<any>(null);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [date, setDate] = React.useState<any>([null, null]);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const isMount = useIsMount();
    const [, debounceValue] = useDebounce(undefined, undefined, searchKey);

    const [pagePayload, setPagePayload] = React.useState<any>({
        page: 0,
        size: 10,
    });

    const {
        data: allReports,
        isFetching: reportsFetching,
        isLoading: reportsLoading,
    } = useGetAllMonthlyReportsQuery<any>(
        {
            ...pagePayload,
            filters: {
                ...(type === 'device' && typeId && { deviceIds: [typeId] }),
                ...(type === 'group' && typeId !== currentOrg?.id && { groupIds: [typeId] }),
                ...pagePayload?.filters,
            },
        },
        { skip: true }
    );

    React.useEffect(() => {
        if (!isMount) {
            setPagePayload((prev: any) => ({
                ...prev,
                searchKey: debounceValue,
            }));
        }
    }, [debounceValue]);

    const [documentsData, setDocumentsData] = React.useState<any>({});

    const [getDocumentsByFilter] = useGetDocumentsByFilterMutation();

    const fetchDocuments = async (): Promise<void> => {
        const documentResponse: any = await getDocumentsByFilter({
            ...pagePayload,
            filters: {
                ...(type === 'device' && typeId && { deviceIds: [typeId] }),
                ...(type === 'group' && typeId !== currentOrg?.id && { groupIds: [typeId] }),
                documentTypes: [categoryId],
                ...pagePayload?.filters,
            },
        });
        const allDocumentsData: any = [];
        documentResponse?.data?.data?.records?.map((modelDocuments: any) => {
            modelDocuments.documentsData?.map((docData: any) => {
                allDocumentsData.push(docData?.documents);
            });
        });
        setDocumentsData((prev: any) => ({
            ...prev,
            [categoryId]: {
                data: allDocumentsData?.flat?.() ?? [],
                total: documentResponse?.data?.data?.total,
            },
        }));
        setIsLoadingMore(false);
    };

    const getCategoryIdContent = (): any => {
        if (categoryId) {
            return (
                <Accordion key={categoryId} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="border-bottom-1"
                    >
                        <Typography
                            variant="body2"
                            fontWeight={'600'}
                            fontSize={'14px'}
                            sx={{ color: theme.palette?.primary?.main }}
                        >
                            {state?.categoryName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="padding-0">
                        <List disablePadding>
                            <>
                                {documentsData?.[categoryId]?.data?.map((document: any) => (
                                    <InfoListItem
                                        onClick={(): void => {
                                            if (document?.url) {
                                                window.open(document?.url);
                                            }
                                        }}
                                        key={document?.url}
                                        divider="partial"
                                        title={document?.displayName}
                                        subtitle={document?.name}
                                        chevron
                                        hidePadding
                                        leftComponent={
                                            <Stack>
                                                <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                                    {new Date(Number(document?.timestamp)).toLocaleString('default', {
                                                        month: 'short',
                                                    })}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    fontSize={'12px'}
                                                    letterSpacing={'1px'}
                                                    textTransform={'uppercase'}
                                                >
                                                    {new Date(Number(document?.timestamp))?.getFullYear()}
                                                </Typography>
                                            </Stack>
                                        }
                                        rightComponent={
                                            <>
                                                {document?.deleteSchedule && (
                                                    <Typography variant="body2" fontSize={'14px'}>
                                                        This file is scheduled for deletion on{' '}
                                                        {moment(new Date(document?.deleteSchedule)).format('LL')}
                                                    </Typography>
                                                )}
                                                <IconButton
                                                    target="_blank"
                                                    download={document?.name}
                                                    href={document?.url}
                                                >
                                                    <Download />
                                                </IconButton>
                                            </>
                                        }
                                    />
                                ))}
                                {(!documentsData?.[categoryId] || isLoadingMore) && (
                                    <Box sx={{ height: 50, position: 'relative' }}>
                                        <Loader size={36} />
                                    </Box>
                                )}
                                {Math.ceil(documentsData?.[categoryId]?.data?.length) <
                                    documentsData?.[categoryId]?.total && (
                                    <InfoListItem
                                        key="showMore"
                                        data-testid="infoListItem"
                                        sx={{ color: theme.palette?.primary?.main }}
                                        data-cy={'list-content'}
                                        title={
                                            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                                                {`View ${
                                                    documentsData?.[categoryId]?.total -
                                                    documentsData?.[categoryId]?.data?.length
                                                } More...`}
                                            </Typography>
                                        }
                                        hidePadding
                                        onClick={(): void => {
                                            setPagePayload((prev: any) => ({ ...prev, size: prev.size + 10 }));
                                            setIsLoadingMore(true);
                                        }}
                                    />
                                )}
                            </>
                        </List>
                    </AccordionDetails>
                </Accordion>
            );
        }
        return (
            <Accordion defaultExpanded>
                <AccordionDetails className="padding-0">
                    <List disablePadding>
                        {allReports?.data?.records?.map((report: any) => (
                            <InfoListItem
                                onClick={(): void => {
                                    if (report?.url) {
                                        window.open(report?.url);
                                    }
                                }}
                                key={report?.fileId}
                                divider="partial"
                                title={report?.reportName}
                                subtitle={report?.fileName}
                                chevron
                                hidePadding
                                leftComponent={
                                    <Stack>
                                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                            {new Date(Number(report?.createdAt)).toLocaleString('default', {
                                                month: 'short',
                                            })}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontSize={'12px'}
                                            letterSpacing={'1px'}
                                            textTransform={'uppercase'}
                                        >
                                            {new Date(Number(report?.timestamp))?.getFullYear()}
                                        </Typography>
                                    </Stack>
                                }
                                // rightComponent={
                                //     document?.deleteSchedule ? (
                                //         <Typography variant="body2" fontSize={'14px'}>
                                //             This file is scheduled for deletion on{' '}
                                //             {moment(new Date(document?.deleteSchedule)).format('LL')}
                                //         </Typography>
                                //     ) : (
                                //         <></>
                                //     )
                                // }
                            />
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    };

    React.useEffect(() => {
        void (async (): Promise<void> => {
            if (currentOrg?.id) {
                await fetchDocuments();
            }
        })();
    }, [pagePayload]);
    return (
        <Stack p={2}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                <InputWithCustomPlaceholder
                    id="searchKey"
                    className="margin-left-10"
                    onChange={(e: any): void => setSearchKey(e.target.value)}
                    inputProps={{ 'aria-label': 'search' }}
                    placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                />

                <IconButton id="filterList" onClick={(): void => setFilterOpen(!filterOpen)}>
                    <FilterList className="color-content" />
                </IconButton>
            </Stack>
            {filterOpen && categoryId && (
                <DocumentFilter
                    payload={pagePayload}
                    setPayload={setPagePayload}
                    customDate={date}
                    setCustomDate={setDate}
                    handleOpenFilter={(arg: boolean): void => setFilterOpen(arg)}
                />
            )}
            {filterOpen && !categoryId && (
                <ReportsFilter
                    payload={pagePayload}
                    setPayload={setPagePayload}
                    customDate={date}
                    setCustomDate={setDate}
                    handleOpenFilter={(arg: boolean): void => setFilterOpen(arg)}
                />
            )}
            {reportsFetching || reportsLoading ? <Loader size={55} /> : getCategoryIdContent()}
        </Stack>
    );
};
