import { useTreeContext } from './useTreeContext';

export const useContainerClassName = (isOver: boolean): object => {
    const { classes } = useTreeContext();
    let className = classes?.container ?? {};

    if (isOver && classes?.dropTarget) {
        className = { ...className, ...classes.dropTarget };
    }

    return className;
};
