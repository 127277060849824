const getRequiredText = (index: any): string => {
    if (index % 1 === 0 && index % 2 === 0 && index % 3 === 0) {
        return 'Sub Loading';
    } else if (index % 2 === 0) {
        return 'SubTitle Loading';
    }
    return 'Subtitle';
};

export { getRequiredText };
