import React from 'react';
import { useDebounce } from './useDebounce';

type ReturnProps = [
    tableFilters: {
        [key: string]: string | string[];
    }
];

type AcceptedProps = {
    allFilters: Filter[];
    triggeredFilters: {
        [key: string]: string | string[];
    };
    timer?: number;
};

type Filter = {
    [key: string]: any;
};

/**
 * The `useFilteredValues` function is a custom React hook that manages and updates table filters based
 * on triggered filters.
 * @param {AcceptedProps}  - - `allFilters`: An array of all available filters.
 * @returns The function `useFilteredValues` returns an array containing the `tableFilters` state.
 */
export const useFilteredValues = ({ allFilters, triggeredFilters, timer }: AcceptedProps): ReturnProps => {
    const [tableFilters, setTableFilters] = React.useState<ReturnProps[0]>(triggeredFilters);

    const [debouncedFilterChange] = useDebounce((key: string) => {
        setTableFilters((prev: AcceptedProps['triggeredFilters']) => ({
            ...prev,
            [key]: triggeredFilters[key],
        }));
    }, timer ?? 600);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this case,
it is used to trigger the `handleFilterChange` function whenever the `triggeredFilters` prop
changes. */
    React.useEffect(() => {
        handleFilterChange();
    }, [triggeredFilters]);
    const handleFilter = (): void => {
        Object.keys(triggeredFilters).forEach((key) => {
            const appliedFilter = allFilters.find(
                (filter: Filter) => filter?.accessor === key || filter?.filterKey === key
            );
            if (appliedFilter && JSON.stringify(triggeredFilters) !== JSON.stringify(tableFilters)) {
                if (appliedFilter?.isDebounce) {
                    debouncedFilterChange(undefined, key);
                    return;
                }

                if (Object.keys(triggeredFilters).length < Object.keys(tableFilters).length)
                    setTableFilters(triggeredFilters);

                setTableFilters((prev: AcceptedProps['triggeredFilters']) => ({
                    ...prev,
                    [key]: triggeredFilters[key],
                }));
            } else if (
                !Object.hasOwn(triggeredFilters, appliedFilter?.filterKey ?? appliedFilter?.accessor) &&
                Object.hasOwn(tableFilters, appliedFilter?.filterKey ?? appliedFilter?.accessor)
            ) {
                debouncedFilterChange(setTableFilters, triggeredFilters);
            }
        });
    };

    /**
     * The function `handleFilterChange` iterates through all filters and updates the `tableFilters`
     * state based on the `triggeredFilters` state.
     */
    const handleFilterChange = (): void => {
        if (triggeredFilters && Object.keys(triggeredFilters)?.length) {
            handleFilter();
            return;
        }

        debouncedFilterChange(setTableFilters, {});
    };

    return [tableFilters];
};
