import React from 'react';
import { DragLayer } from './DragLayer';
import { Container } from './Container';
import { HierarchyProps } from './types';
import { ConfirmModal } from '../ConfirmModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { useTreeContext } from './hooks';
import { ConfirmModalContext } from './providers';

export const CustomTreeView = (props: HierarchyProps & { args: any; tree: any }): JSX.Element => {
    const { onDrop, handleDropNode } = useTreeContext();
    const [isMoving, setIsMoving] = React.useState(false);
    const [confirmModalProps, setConfirmModalProps] = React.useState(props?.confirmModalProps);
    const handleConfirm = (args: any): void => {
        setIsMoving(true);
        handleDropNode?.(args)
            .then(() => {
                onDrop(args);
                setIsMoving(false);
                closeConfirmModal();
            })
            .catch((error: any) => {
                setIsMoving(false);
                console.error('[Hierarchy] error: ', error);
            });
    };

    const {
        isVisible: isConfirmModalOpen,
        onClick: openConfirmModal,
        onCancel: closeConfirmModal,
        onConfirm: confirmSuccess,
        data,
    } = useConfirm(handleConfirm);

    const confirmModalContextValue = React.useMemo(() => ({ openConfirmModal, data }), [openConfirmModal, data]);

    React.useEffect(() => {
        (String as any).prototype.interpolate = function (params: any): any {
            const names = Object.keys(params);
            const vals = Object.values(params);
            // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
            return new Function(...names, `return \`${this}\`;`)(...vals);
        };
    }, []);

    const replaceDynamicString = (sourceString: string, sourceData: any): string => {
        const startIndex = sourceString?.indexOf('{{');
        const endIndex = sourceString?.indexOf('}}');
        const trimmedString = sourceString.slice(startIndex, endIndex + 2);
        const convertedString: any = trimmedString.replace(
            trimmedString,
            '${' + `${trimmedString.slice(2, trimmedString.length - 2)}` + '}'
        );
        const interpolatedString = convertedString?.interpolate(sourceData);
        return sourceString.replace(trimmedString, interpolatedString);
    };

    React.useEffect(() => {
        if (isConfirmModalOpen) {
            const modifiedConfirmModalProps: any = {};
            if (confirmModalProps?.header?.includes('{{') && confirmModalProps?.header?.includes('}}')) {
                modifiedConfirmModalProps.header = replaceDynamicString(confirmModalProps?.header, data);
            }
            if (confirmModalProps?.description?.includes('{{') && confirmModalProps?.description?.includes('}}')) {
                modifiedConfirmModalProps.description = replaceDynamicString(confirmModalProps?.description, data);
            }
            if (Object.keys(modifiedConfirmModalProps)?.length) {
                setConfirmModalProps((prevState: any) => ({ ...prevState, ...modifiedConfirmModalProps }));
            }
        }
    }, [confirmModalProps, isConfirmModalOpen]);

    React.useEffect(() => {
        if (!isConfirmModalOpen) {
            setConfirmModalProps(props?.confirmModalProps);
        }
    }, [isConfirmModalOpen]);

    return (
        <ConfirmModalContext.Provider value={confirmModalContextValue}>
            {props?.args?.dragPreviewRender && <DragLayer />}
            <Container parentNode={props?.parentNode} depth={0} nodes={props?.tree} />
            <ConfirmModal
                actionButtonText="Move"
                description="Confirm Move?"
                isVisible={isConfirmModalOpen}
                header="Confirm Move"
                confirmClick={confirmSuccess}
                onCancel={closeConfirmModal}
                isLoading={isMoving}
                {...(confirmModalProps ?? {})}
            />
        </ConfirmModalContext.Provider>
    );
};
