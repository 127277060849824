import { useTypedSelector } from '@fiji/common/src/app/store';
import { useGetSnoozeByDeviceIdQuery } from '@fiji/common/src/features/Timeline/timelineApi';
import { setSnoozeIsVisible, setTimerString } from '@fiji/common/src/features/Timeline/timelineSlice';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatTime } from 'utils/helpers';
export const useCountdownTimer = (deviceId: string, isRefetch: boolean): any => {
    const currentOrg: any = useTypedSelector(selectedOrg);
    const dispatch = useDispatch();
    const [time, setTime] = React.useState(0);

    const [initialTime, setInitialTime] = React.useState(0);
    const { data: snoozeData, refetch }: any = useGetSnoozeByDeviceIdQuery(
        { deviceId },
        { skip: !deviceId || !currentOrg?.id }
    );

    React.useEffect(() => {
        let snoozeTimer: any;
        if (snoozeData?.data?.duration > 0 && isRefetch) {
            const snoozeDuration = snoozeData?.data?.duration + snoozeData?.data?.snoozedAt - Date.now();
            snoozeTimer = setTimeout(() => {
                refetch();
            }, snoozeDuration ?? 0);
            setTime(Math.floor(snoozeDuration / 1000));
            setInitialTime(Math.floor(snoozeDuration / 1000));
        }
        return () => {
            clearTimeout(snoozeTimer);
            dispatch(setSnoozeIsVisible(false));
            setTime(0);
        };
    }, [snoozeData]);

    React.useEffect(() => {
        let timer: any;
        setTime(initialTime);
        if (initialTime > 0) {
            timer = setInterval(() => {
                setTime((prevTime: any) => {
                    if (prevTime === 0) {
                        clearInterval(timer);
                        return prevTime;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        // Clean up timer when component unmounts
        return (): any => clearInterval(timer);
    }, [initialTime]); // Run effect whenever initialTime changes

    React.useEffect(() => {
        if (time > 0) {
            dispatch(
                setTimerString(
                    formatTime({
                        hours: Math.floor(time / 3600),
                        minutes: Math.floor((time % 3600) / 60),
                        seconds: time % 60,
                    })
                )
            );
            dispatch(setSnoozeIsVisible(true));
        } else {
            dispatch(setSnoozeIsVisible(false));
        }
    }, [time]);

    return {
        isVisible: snoozeData?.data?.duration > 0,
    };
};
