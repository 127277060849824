const colors = [
    '#1D2529',
    '#B9BFC2',
    '#BD2E2F',
    '#E1B7C2',
    '#983FEF',
    '#6A1BE4',
    '#004B9E',
    '#007BC1',
    '#4DACF6',
    '#4DC9C3',
    '#178E0B',
    '#2CA618',
    '#ADBC22',
    '#74CC63',
    '#EEC62A',
    '#F7A064',
    '#F36F1D',
    '#EC470B',
    '#774E08',
    '#727E84',
];
export const COMMAND_PALETTE = colors.flatMap((color, index) => [
    { id: `${index * 2 + 1}`, type: 'contained', color },
    { id: `${index * 2 + 2}`, type: 'outlined', color },
]);
