import { DropTargetMonitor } from 'react-dnd';
import { isDroppable } from './isDroppable';
import { NodeModel, TreeState, DragItem } from '../types';

type CompareResult = 'up' | 'down';

type VerticalPosition = 'upper' | 'middle' | 'lower';

type DropTarget = {
    id: NodeModel['id'];
    index: number;
    name: string;
} | null;

type CompareYCoord = (el: Element, pointerY: number) => CompareResult;

type GetInnerIndex = (listItems: NodeListOf<Element>, monitor: DropTargetMonitor) => number;

type GetOuterIndex = (node: NodeModel, nodeEl: HTMLElement, monitor: DropTargetMonitor) => number | null;

const compareYCoord: CompareYCoord = (el, pointerY) => {
    const bbox = el.getBoundingClientRect();
    const centerY = bbox.top + bbox.height / 2;
    return pointerY > centerY ? 'down' : 'up';
};

const getInnerIndex: GetInnerIndex = (listItems, monitor) => {
    let pos = '';
    let index = 0;

    listItems.forEach((el, key) => {
        const flag = compareYCoord(el, monitor.getClientOffset()?.y || 0);

        if (pos === '') {
            pos = flag;
        } else if (pos !== flag) {
            pos = flag;
            index = key;
        }

        if (key === listItems.length - 1 && flag === 'down') {
            index = key + 1;
        }
    });

    return index;
};

const getOuterIndex: GetOuterIndex = (node, nodeEl, monitor) => {
    const parentList = nodeEl.closest('.dnd-container');
    const parentListItems = parentList?.querySelectorAll(':scope > .dnd-item');

    if (!parentListItems) {
        return null;
    }

    return getInnerIndex(parentListItems, monitor);
};

const getHoverPosition = <T>(el: Element, pointerY: number, context: TreeState<T>): VerticalPosition => {
    const bbox = el.getBoundingClientRect();
    const offsetY = context.dropTargetOffset;
    const upSideY = bbox.top + offsetY;
    const lowerSideY = bbox.bottom - offsetY;

    if (pointerY > lowerSideY) {
        return 'lower';
    } else if (pointerY < upSideY) {
        return 'upper';
    }

    return 'middle';
};

export const getDropTarget = <T>(
    node: NodeModel<T> | null,
    parentNode: any,
    nodeEl: HTMLElement | null,
    monitor: DropTargetMonitor,
    context: TreeState<T>
): DropTarget => {
    const labelKey = context?.labelKey ?? 'displayName';
    if (!nodeEl) {
        return null;
    }

    if (node === null) {
        const listItems = nodeEl.querySelectorAll(':scope > .dnd-item');

        return {
            id: context.parentNode?.id,
            name: context.parentNode?.[labelKey],
            index: getInnerIndex(listItems, monitor),
        };
    }

    const dragSource: DragItem<T> = monitor.getItem();
    const list = nodeEl.querySelector('.dnd-container');
    const hoverPosition = getHoverPosition(nodeEl, monitor.getClientOffset()?.y || 0, context);

    if (!list && hoverPosition === 'middle') {
        return {
            id: node.id,
            name: node?.[labelKey],
            index: 0,
        };
    }
    if (!list && isDroppable(dragSource, parentNode?.id, context)) {
        const outerIndex = getOuterIndex(node, nodeEl, monitor);

        if (outerIndex === null) {
            return null;
        }

        return {
            id: parentNode?.id,
            name: parentNode?.[labelKey],
            index: outerIndex,
        };
    }
    if (!list) {
        return null;
    }
    if (hoverPosition === 'upper' && isDroppable(dragSource, parentNode?.id, context)) {
        const outerIndex = getOuterIndex(node, nodeEl, monitor);

        if (outerIndex === null) {
            return null;
        }

        return {
            id: parentNode?.id,
            name: parentNode?.[labelKey],
            index: outerIndex,
        };
    }
    if (hoverPosition === 'upper') {
        return {
            id: node.id,
            name: node?.[labelKey],
            index: 0,
        };
    }

    const listItems = list.querySelectorAll(':scope > .dnd-item');

    return {
        id: node.id,
        name: node?.[labelKey],
        index: getInnerIndex(listItems, monitor),
    };
};
