import { Button, Checkbox, Divider, MenuItem } from '@mui/material';
import { StyledMenu } from 'pages/Chart/StyledMenu';
import React, { useState } from 'react';
import { CustomTransComponent } from './CustomTransComponent';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const UnitListComponent = ({ unitList, selectedUnits, setSelectedUnits }: any): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleUnitChange = (value: string): void => {
        setSelectedUnits?.((prev: any) =>
            prev.includes(value) ? prev.filter((unit: any) => unit !== value) : [...prev, value]
        );
    };

    const handleSelectAll = (checked: boolean): void => {
        setSelectedUnits(checked ? unitList : []);
    };

    return (
        <>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    backgroundColor: 'transparent',
                    color: '#424E54',
                }}
                onClick={handleClick}
                endIcon={!open ? <ArrowDropDown /> : <ArrowDropUp />}
            >
                <CustomTransComponent translationKey="COMMON:UNITS" />
            </Button>
            <StyledMenu
                id="unit-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem className="w-100" key="all">
                    <Checkbox
                        indeterminate={selectedUnits.length > 0 && selectedUnits.length < unitList.length}
                        checked={selectedUnits.length === unitList.length}
                        onChange={(e): void => handleSelectAll(e.target.checked)}
                    />
                    <CustomTransComponent translationKey="COMMON:SELECT_ALL" />
                </MenuItem>
                <Divider />
                {unitList.map((unit: any) => (
                    <MenuItem key={unit} value={unit} className="w-100">
                        <Checkbox checked={selectedUnits.includes(unit)} onChange={() => handleUnitChange(unit)} />
                        {`(${unit}) ${unit}`}
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    );
};

export default UnitListComponent;
