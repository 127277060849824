import React from 'react';

import { createNumArray } from 'utils/helpers';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import { ListItem, ListItemButton, ListItemText, Skeleton } from '@mui/material';

export const TypesSkeleton = ({
    items,
    primaryVariant,
    text,
}: {
    items: number;
    primaryVariant?: 'rectangular' | 'circular';
    text?: string;
}): JSX.Element => (
    <>
        {createNumArray(items)?.map((item: any) => (
            <ListItem sx={{ paddingX: '8px' }} key={item}>
                <ListItemButton className="list-item-button" role={undefined} dense>
                    <Skeleton animation="wave" variant={primaryVariant ?? 'rounded'} height={25} width={25}>
                        <FormatListBulleted />
                    </Skeleton>

                    <Skeleton sx={{ marginLeft: '20px' }} animation="wave">
                        <ListItemText primary={text ?? 'demo widget'} />
                    </Skeleton>
                </ListItemButton>
            </ListItem>
        ))}
    </>
);
