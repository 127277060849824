import React from 'react';

import dayjs from 'dayjs';
import { Grid, Stack } from '@mui/material';
import { TimeSlotGrid } from './TimeSlotGrid';
import { WeekViewHeader } from './WeekViewHeader';
import { useTypedSelector } from '@fiji/common';
import { createNumArray } from 'utils/helpers';
import { WeekViewCurrentTime } from './WeekViewCurrentTime';
import { WeekEventGrid } from './WeekEventGrid';

export const WeekView = (): JSX.Element => {
    const gridRef: any = React.useRef<any>(null);
    const [gridWidth, setGridWidth] = React.useState(0);

    const schedules = useTypedSelector((state) => state['schedule']['schedules']);
    const currentTime = useTypedSelector((state: any) => state['schedule']['currentTime']);

    const updateGridWidth = (): void => {
        if (gridRef.current) {
            setGridWidth(gridRef?.current?.offsetWidth);
        }
    };

    React.useEffect(() => {
        updateGridWidth();
        window.addEventListener('resize', updateGridWidth);

        return () => {
            window.removeEventListener('resize', updateGridWidth);
        };
    }, []);

    const selectedWeekDays = useTypedSelector((state) => state['schedule']['selectedWeekDays']);
    return (
        <div
            style={{ position: 'relative', paddingTop: 76, paddingLeft: 24, overflow: 'auto' }}
            className="margin-top-36"
        >
            <Grid container>
                <Grid item xs={0.5}>
                    <TimeSlotGrid />
                </Grid>
                <Grid item xs={11.5}>
                    <WeekViewHeader />

                    <Grid sx={{ position: 'relative' }} container ref={gridRef}>
                        {selectedWeekDays?.some((item: any) => item?.dayOfMonth === dayjs(currentTime)?.date()) && (
                            <WeekViewCurrentTime />
                        )}
                        {createNumArray(24)?.map((item) =>
                            selectedWeekDays?.map((unusedDay: any) => (
                                <Grid item xs={12 / 7} key={`${item}${unusedDay?.dayOfMonth}`}>
                                    <Stack
                                        style={{
                                            height: 60,
                                            display: 'flex',
                                            margin: 0,
                                            ...(unusedDay?.dayOfMonth === parseInt(dayjs(currentTime)?.format('D')) && {
                                                backgroundColor: '#f2f8fc',
                                            }),
                                        }}
                                        className="border-right-1 border-bottom-1"
                                    />
                                </Grid>
                            ))
                        )}
                        {schedules?.map((schedule: any) => (
                            <WeekEventGrid key={schedule?.id} event={schedule} gridWidth={gridWidth} />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
