import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List, Stack, Typography } from '@mui/material';
import React from 'react';
import { InfoListItem } from '@brightlayer-ui/react-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import moment from 'moment';
import { CustomTransComponent, Loader } from 'components';
import { useTheme } from '@mui/material/styles';
import { useGetAllMonthlyReportsQuery } from '@fiji/common/src/features/reports/reportApi';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useGetDocumentsByFilterMutation,
    useGetDocumentTypesQuery,
} from '@fiji/common/src/features/documents/documentApi';
import { Download } from '@mui/icons-material';

export const Document = (): JSX.Element => {
    const navigate = useNavigate();
    const { deviceId, groupId } = useParams();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const theme: any = useTheme();
    const currentOrg = useTypedSelector(selectedOrg);
    const [expandedNodes, setExpandedNodes] = React.useState<any>([]);
    const [documentsData, setDocumentsData] = React.useState<any>({});

    const {
        data: documentTypeData,
        isLoading: documentFetching,
        isFetching: documentLoading,
    } = useGetDocumentTypesQuery(
        {
            ...(deviceId && { deviceIds: [deviceId] }),
            ...(groupId && groupId !== currentOrg?.id && { groupIds: [groupId] }),
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const [getDocumentsByFilter] = useGetDocumentsByFilterMutation();

    const getAllDocumentsData = (documentResponse: any): any => {
        const allDocumentsData: any = [];
        documentResponse?.data?.data?.records?.map((modelDocuments: any) => {
            modelDocuments.documentsData?.map((docData: any) => {
                allDocumentsData.push(docData?.documents);
            });
        });
        return allDocumentsData?.flat?.() ?? [];
    };

    React.useEffect(() => {
        if (currentOrg?.id && expandedNodes?.length > 0) {
            expandedNodes.map(async (node: string) => {
                if (!documentsData[node]) {
                    const documentResponse: any = await getDocumentsByFilter({
                        page: 0,
                        size: 6,
                        filters: {
                            ...(deviceId && { deviceIds: [deviceId] }),
                            ...(groupId && groupId !== currentOrg?.id && { groupIds: [groupId] }),
                            documentTypes: [node],
                        },
                    });

                    setDocumentsData((prev: any) => ({
                        ...prev,
                        [node]: {
                            data: getAllDocumentsData(documentResponse),
                            total: documentResponse?.data?.data?.total,
                        },
                    }));
                }
            });
        }
    }, [expandedNodes]);

    const { data: allReports } = useGetAllMonthlyReportsQuery<any>(
        {
            page: 0,
            size: 6,
            filters: {
                ...(deviceId && { deviceIds: [deviceId] }),
                ...(groupId && groupId !== currentOrg?.id && { groupIds: [groupId] }),
            },
        },
        { skip: true }
    );

    const handleExpandCollapseNode = (categoryId: string): void => {
        const expandedNodesClone = JSON.parse(JSON.stringify(expandedNodes));
        const nodeIndex = expandedNodesClone.findIndex((node: string) => node === categoryId);
        if (nodeIndex !== -1) {
            expandedNodesClone.splice(nodeIndex, 1);
        } else {
            expandedNodesClone.push(categoryId);
        }
        setExpandedNodes(expandedNodesClone);
    };

    return (
        <Stack p={2}>
            {documentFetching || documentLoading ? (
                <Loader size={55} />
            ) : (
                <>
                    <>
                        {allReports?.data?.records?.map((report: any) => (
                            <InfoListItem
                                onClick={(): void => {
                                    if (report?.url) {
                                        window.open(report?.url);
                                    }
                                }}
                                key={report?.fileId}
                                divider="partial"
                                title={report?.reportName}
                                subtitle={report?.fileName}
                                chevron
                                hidePadding
                                leftComponent={
                                    <Stack>
                                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                            {new Date(Number(report?.createdAt)).toLocaleString('default', {
                                                month: 'short',
                                            })}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontSize={'12px'}
                                            letterSpacing={'1px'}
                                            textTransform={'uppercase'}
                                        >
                                            {new Date(Number(report?.timestamp))?.getFullYear()}
                                        </Typography>
                                    </Stack>
                                }
                            />
                        ))}

                        {Math.ceil(allReports?.data?.total) < 6 && (
                            <List>
                                <InfoListItem
                                    key="showMore"
                                    data-testid="infoListItem"
                                    data-cy={'list-content'}
                                    title={
                                        <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                                            <CustomTransComponent translationKey={'COMMON:SHOW_MORE'} />
                                        </Typography>
                                    }
                                    hidePadding
                                    onClick={(): void => {
                                        navigate(
                                            `/${currentRealmName}/report-list/${deviceId ? 'device' : 'group'}/${
                                                deviceId ?? groupId
                                            }`
                                        );
                                    }}
                                />
                            </List>
                        )}
                    </>
                    {documentTypeData?.data?.length > 0 && (
                        <>
                            {documentTypeData?.data?.map((documentType: any) => (
                                <Accordion
                                    key={documentType?.categoryId}
                                    expanded={expandedNodes?.includes(documentType?.categoryId)}
                                    onChange={() => handleExpandCollapseNode(documentType?.categoryId)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color="primary" />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className="border-bottom-1"
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight={'600'}
                                            fontSize={'14px'}
                                            sx={{ color: theme.palette?.primary?.main }}
                                        >
                                            {documentType?.categoryName}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="padding-0">
                                        <List disablePadding>
                                            {documentsData?.[documentType?.categoryId] ? (
                                                <>
                                                    {documentsData?.[documentType?.categoryId]?.data?.map(
                                                        (document: any) => (
                                                            <InfoListItem
                                                                onClick={(): void => {
                                                                    if (document?.url) {
                                                                        window.open(document?.url);
                                                                    }
                                                                }}
                                                                key={document?.url}
                                                                divider="partial"
                                                                title={document?.displayName}
                                                                subtitle={document?.name}
                                                                chevron
                                                                hidePadding
                                                                leftComponent={
                                                                    <Stack>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            fontSize={'14px'}
                                                                            fontWeight={'600'}
                                                                        >
                                                                            {new Date(
                                                                                Number(document?.timestamp)
                                                                            ).toLocaleString('default', {
                                                                                month: 'short',
                                                                            })}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body2"
                                                                            fontSize={'12px'}
                                                                            letterSpacing={'1px'}
                                                                            textTransform={'uppercase'}
                                                                        >
                                                                            {new Date(
                                                                                Number(document?.timestamp)
                                                                            )?.getFullYear()}
                                                                        </Typography>
                                                                    </Stack>
                                                                }
                                                                rightComponent={
                                                                    <>
                                                                        {document?.deleteSchedule && (
                                                                            <Typography
                                                                                variant="body2"
                                                                                fontSize={'14px'}
                                                                            >
                                                                                This file is scheduled for deletion on{' '}
                                                                                {moment(
                                                                                    new Date(document?.deleteSchedule)
                                                                                ).format('LL')}
                                                                            </Typography>
                                                                        )}
                                                                        <IconButton
                                                                            target="_blank"
                                                                            download={document?.name}
                                                                            href={document?.url}
                                                                        >
                                                                            <Download />
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            />
                                                        )
                                                    )}
                                                    {Math.ceil(
                                                        documentsData?.[documentType?.categoryId]?.data?.length
                                                    ) < documentsData?.[documentType?.categoryId]?.total && (
                                                        <InfoListItem
                                                            key="showMore"
                                                            data-testid="infoListItem"
                                                            sx={{ color: theme.palette?.primary?.main }}
                                                            data-cy={'list-content'}
                                                            title={
                                                                <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                                                                    {`View ${
                                                                        documentsData?.[documentType?.categoryId]
                                                                            ?.total -
                                                                        documentsData?.[documentType?.categoryId]?.data
                                                                            ?.length
                                                                    } More...`}
                                                                </Typography>
                                                            }
                                                            hidePadding
                                                            onClick={(): void => {
                                                                navigate(
                                                                    `/${currentRealmName}/document-list/${
                                                                        deviceId ? 'device' : 'group'
                                                                    }/${deviceId ?? groupId}/${
                                                                        documentType?.categoryId
                                                                    }`,
                                                                    {
                                                                        state: {
                                                                            categoryName: documentType?.categoryName,
                                                                        },
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <Box sx={{ height: 50 }}>
                                                    <Loader size={36} />
                                                </Box>
                                            )}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};
