import React, { useEffect } from 'react';
import { Chip, Paper, Stack } from '@mui/material';
import { Device as DeviceIcon } from '@brightlayer-ui/icons-mui';
import {
    useGetAllDevicesQuery,
    useDeleteDeviceMutation,
    useUpdateDeviceTableMutation,
    useGetDeviceTableQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Filter } from './Filter';
import { DeleteDeviceModal, DataGrid } from '../../../components';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useDebounce, useIsMount, useRBAC } from '../../../hooks';
import { useConfirm } from '@fiji/common/src/hooks';
import { moveGroupsDevicesModal } from '@fiji/common/src/features/group/groupSlice';
import { setDeviceSideBar, setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useTranslation } from 'react-i18next';
import { Device } from '@fiji/common/src/types/Device';
import { EditDeviceDrawer } from './EditDeviceDrawer';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { DeviceModal } from '@fiji/common/src/types';
import {
    deleteDeviceModal,
    deviceFilters,
    resetSortPayload,
    resetDeviceFilters,
    selectDeleteDeviceModal,
    setDeviceFilters,
    setSortPayload,
    setDeviceChips,
    resetDeviceChips,
} from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { DeviceColumns } from './DeviceColumns';
import { DEVICE_CHIPS } from '@fiji/common';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
import { UpdateModal } from 'pages/Properties/UpdateModal';

export const Devices = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMount = useIsMount();
    const dispatch = useAppDispatch();
    const [searchKey, setSearchKey] = React.useState<string | undefined>(undefined);
    const filterRef = React.useRef<any>(null);
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const sidebar = useTypedSelector((state) => state.common.deviceSideBar);
    const deleteDeviceModalData = useTypedSelector<DeviceModal>(selectDeleteDeviceModal);
    const currentOrg = useTypedSelector(selectedOrg);
    const selectedNode = useTypedSelector((state: any) => state?.common?.selectedNode);
    const configurablePayload = useTypedSelector((state) => state.dataGrid.configurablePayload);
    const paginationPayload = useTypedSelector((state) => state.dataGrid.paginationPayload);
    const filters = useTypedSelector(deviceFilters);
    const sortPayload = useTypedSelector((state: any) => state.device.sortPayload);
    const [selectedDeviceIds, setSelectedDeviceIds] = React.useState<GridRowSelectionModel>([]);
    const [deviceIdsToBeDeleted, setDeviceIdsToBeDeleted] = React.useState(selectedDeviceIds);
    const updateModalRef: any = React.useRef();
    const { groupId, deviceId: routeDevice } = useParams();

    const {
        currentData: deviceList,
        isLoading: deviceListLoader,
        isFetching: deviceListFetching,
        isSuccess: deviceListSuccess,
        isError: deviceListError,
    } = useGetAllDevicesQuery(
        {
            page: paginationPayload?.page,
            size: paginationPayload?.pageSize,
            ...(!isMount && { searchKey: debouncedValue }),
            ...(Object.keys(filters)?.length && { filters }),
            ...(Object.keys(sortPayload)?.length && { sort: sortPayload }),
        },
        {
            skip:
                !currentOrg?.id?.length ||
                (selectedNode && !Object.keys(filters)?.length) ||
                (groupId !== currentOrg?.id && selectedNode?.id !== groupId && selectedNode?.id !== routeDevice),
            refetchOnMountOrArgChange: true,
        }
    );
    const [deleteDevice, { isLoading: isDeviceDeleting, isSuccess }] = useDeleteDeviceMutation({
        fixedCacheKey: 'deleteDevice',
    });

    const [filteredDevices, setFilteredDevices] = React.useState(deviceList?.data?.records);
    const chips = useTypedSelector((state: any) => state.device.chips);
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canCreateDevice = hasPermission('create-devices');
    const canDeleteDevice = hasPermission('delete-devices');
    const canUpdateDevice = hasPermission('edit-devices');

    const [updateTable, { isLoading }] = useUpdateDeviceTableMutation();
    const { data: deviceTable } = useGetDeviceTableQuery('device');

    useEffect(() => {
        if (deleteDeviceModalData?.isOpen) {
            handleDeleteConfirmModal();
        }
    }, [deleteDeviceModalData]);

    React.useEffect(() => {
        if (Object.keys(filters).length) {
            for (const key in filters) {
                if (!chips?.includes(DEVICE_CHIPS[key])) {
                    dispatch(setDeviceChips(DEVICE_CHIPS[key]));
                }
            }
        }
    }, [filters]);

    const handleDelete = async (): Promise<void> => {
        const { error }: any = await deleteDevice({ deviceIds: deviceIdsToBeDeleted });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('DEVICE_MANAGEMENT:DELETE_DEVICES_SUCCESS', {
                        replace: {
                            deviceIds: `${deviceIdsToBeDeleted.length}`,
                        },
                    }),

                    duration: 3000,
                })
            );
            onCancel();
            dispatch(setDeviceSideBar({ isEnable: false, sideBarOpen: false }));
            dispatch(deleteDeviceModal({ isOpen: false }));
        }
    };
    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(handleDelete);
    const columnsChange = (payloadKey: any): boolean => {
        let bool = true;
        for (const key in configurablePayload[payloadKey]) {
            if (Array.isArray(configurablePayload[payloadKey][key])) {
                if (
                    !deviceTable?.data?.[payloadKey]?.[key]?.every((item: any) =>
                        configurablePayload[payloadKey][key]?.includes(item)
                    ) ||
                    deviceTable?.data?.[payloadKey]?.[key]?.length !== configurablePayload[payloadKey][key]?.length
                )
                    bool = false;
            } else if (configurablePayload[payloadKey][key] !== deviceTable?.['data']?.[payloadKey]?.[key]) {
                bool = false;
            }
        }

        return bool;
    };

    React.useEffect(() => {
        if (deviceTable) {
            if (!columnsChange('visibleColumns') || !columnsChange('pinnedColumns'))
                updateTable({ body: configurablePayload, tableType: 'device' }) as any;
        }
    }, [configurablePayload, deviceTable]);

    React.useEffect(() => {
        if (selectedDeviceIds?.length > 0) {
            dispatch(
                moveGroupsDevicesModal({
                    deviceIds: selectedDeviceIds?.map((deviceId: any) => ({
                        id: deviceId,
                        name: deviceList?.data?.records?.find((device: any) => device?.id === deviceId)?.name,
                    })),
                })
            );
        }
        if (selectedDeviceIds?.length) {
            dispatch(
                setDeviceSideBar({
                    ...sidebar,
                    isEnable: true,
                    selectedDeviceIds,
                })
            );
        } else {
            dispatch(
                setDeviceSideBar({
                    isEnable: false,
                    selectedDeviceIds,
                })
            );
        }
    }, [selectedDeviceIds]);

    const onDeleteDeviceCancel = (): void => {
        onCancel();
        dispatch(deleteDeviceModal({ isOpen: false }));
    };

    const handleDeleteConfirmModal = (id?: string): void => {
        const allDevicesClone = JSON.parse(JSON.stringify(deviceList?.data));
        if (id) {
            setSelectedDeviceIds([id]);
            allDevicesClone.records = allDevicesClone.records.filter((device: Device) => device?.id === id);
        } else {
            allDevicesClone.records = allDevicesClone.records.filter((device: Device) =>
                selectedDeviceIds.includes(device?.id)
            );
        }
        onClick(id);

        setFilteredDevices(allDevicesClone?.records);
    };

    const onEditDeviceCancel = (): void => {
        dispatch(
            setDeviceSideBar({
                isEnable: false,
                sideBarOpen: false,
            })
        );
        setSelectedDeviceIds([]);
        setFilteredDevices([]);
    };

    const handleSortChange = (data: any): void => {
        dispatch(setSortPayload({ key: data[0]?.field, value: data[0]?.sort?.toUpperCase() }));
    };

    React.useEffect(
        () => () => {
            dispatch(resetDeviceFilters());
            dispatch(resetSortPayload());
            dispatch(resetDeviceChips());
        },
        []
    );

    useEffect(() => {
        if (!selectedNode?.wrapperDeviceType && selectedNode) {
            dispatch(setDeviceFilters({ key: 'groups', value: [selectedNode.id] }));
        } else if (selectedNode && selectedNode?.wrapperDeviceType === 'Gateway') {
            dispatch(setDeviceFilters({ key: 'parentId', value: [selectedNode.id] }));
        }
    }, [selectedNode]);

    return (
        <Stack p={3}>
            <Stack direction={'row'} py={1} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <InputWithCustomPlaceholder
                        id="searchKey"
                        data-cy={'searchKey'}
                        className="margin-left-10"
                        value={searchKey}
                        onChange={(e: any): void => {
                            setSearchKey(e?.target?.value?.trimStart());
                        }}
                        placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                    <Filter ref={filterRef} />
                </Stack>
            </Stack>
            <Stack direction="row" marginBottom={'16px'} justifyContent={'end'} spacing={1}>
                {chips.map((key: string) => (
                    <Chip
                        key={`unique${key}`}
                        label={key}
                        onDelete={filterRef?.current?.toggleFilterDrawer('right', true)}
                    />
                ))}
            </Stack>

            <Paper
                className={`margin-y-16 ${
                    deviceListLoader || deviceListFetching || !deviceList?.data?.records?.length ? 'h-100' : ''
                }`}
            >
                <DataGrid
                    paginationLabel={'Items per page:'}
                    columns={DeviceColumns({
                        handleDeleteConfirmModal,
                        setSelectedDeviceIds,
                        canCreateDevice,
                        updateTags: updateModalRef?.current?.handleModalAction,
                    })}
                    isSelectable={canUpdateDevice | canDeleteDevice}
                    selectedIds={selectedDeviceIds}
                    setSelectedIds={setSelectedDeviceIds}
                    total={deviceList?.data?.total}
                    rows={deviceList?.data?.records ?? []}
                    handleSortChange={handleSortChange}
                    isLoading={
                        (deviceListLoader || deviceListFetching || isLoading) &&
                        (!deviceListSuccess || !deviceListError)
                    }
                    onFilterClick={filterRef?.current?.toggleFilterDrawer('right', true)}
                    noDataFoundIcon={<DeviceIcon fontSize="inherit" />}
                    noDataFoundTitle={t('DEVICE_MANAGEMENT:NO_DEVICE_FOUND')}
                    {...(canCreateDevice && {
                        noDataFoundButtonText: t('COMMON:ADD_DEVICE'),
                        noDataFoundButtonAction: (): void => navigate(`/${currentRealmName}/add-device`),
                    })}
                />
            </Paper>
            <UpdateModal ref={updateModalRef} header={'Add/ Edit Tags'} isSubHeader={true} />

            <DeleteDeviceModal
                titleKey="name"
                titleKey1="model"
                keyToTraverse="id"
                subtitleKey="groupPath"
                filteredData={filteredDevices ?? []}
                selectedIds={selectedDeviceIds as any}
                isLoading={isDeviceDeleting}
                deleteSuccess={isSuccess}
                handleIdsToBeDeleted={setDeviceIdsToBeDeleted}
                handleModalClose={onDeleteDeviceCancel}
                isOpen={isVisible}
                handleConfirmDelete={onConfirm}
                header={t('DEVICE_MANAGEMENT:MULTI_ITEMS_MODAL_HEADERS')}
                deleteModalHeader={t('DEVICE_MANAGEMENT:MULTI_ITEMS_MODAL_HEADERS')}
                deleteModalDescription={t('DEVICE_MANAGEMENT:DELETE_MODAL_DESCRIPTION', {
                    total: deviceIdsToBeDeleted?.length,
                })}
                deleteButtonText={'DEVICE_MANAGEMENT:DELETE_DEVICE_BTN'}
            />
            <EditDeviceDrawer selectedDevices={selectedDeviceIds} onEditDeviceCancel={onEditDeviceCancel} />
        </Stack>
    );
};
