import React, { useImperativeHandle } from 'react';

import { useConfirm, useSearchDeviceOrGroup } from '@fiji/common/src/hooks';
import { CustomModal, CustomTransComponent, Hierarchy } from '../../../components';
import { Stack, FormControl, TextField, Typography } from '@mui/material';
import { ModalActions } from '../../../components/CustomModal';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import { useSelectedIds } from '../../../hooks';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { GroupHierarchySkeleton } from '../../GroupHierarchy/GroupHierarchySkeleton';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useUpdateDashboardMutation } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import Search from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

export const AssociateDeviceModal = React.forwardRef((_props: any, ref): JSX.Element => {
    const { t } = useTranslation();
    const currentOrg = useTypedSelector(selectedOrg);
    const dispatch = useAppDispatch();

    const [groupsData, setGroupsData] = React.useState<any>();
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState<any>({});
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [getAllGroups, { data: groupsHierarchy }]: any = useGetAllGroupsMutation();
    const [associateDevices, { isLoading: isUpdatingDashboard }] = useUpdateDashboardMutation();
    const [searchKey, setSearchKey] = React.useState(null);

    const { currentData: filteredRecords, isLoading } = useSearchDeviceOrGroup({ searchKey });

    React.useEffect(() => {
        if (!groupsData) {
            setGroupsData(groupsHierarchy?.data?.records);
        }
    }, [groupsHierarchy]);

    React.useEffect(() => {
        if (currentOrg?.id) {
            getAllGroups({});
        }
    }, [currentOrg]);

    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getAllGroups({ parent: parentData?.id });
        if (childHierarchyData) {
            setHierarchyMappingData((prevState: any) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
    };

    const [selectedIds, setSelectedIds] = useSelectedIds();

    const groupSelectionHandler = (id: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(selectedIds));
        const uncheckedIndex = payloadClone?.indexOf(id);
        if (uncheckedIndex !== -1) {
            payloadClone?.splice(uncheckedIndex, 1);
        } else if (id) {
            payloadClone?.push(id);
        }
        setSelectedIds(payloadClone);
    };

    const handleDashboardCreation = async (data: any): Promise<void> => {
        const { error }: any = await associateDevices({
            body: { assignType: 'DEVICE', assignTypeIds: selectedIds },
            params: { dashboardId: data?.id ?? '' },
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('DASHBOARDS:ASSOCIATE_DEVICES_SUCCESS_MSG', {
                        replace: {
                            name: data?.name,
                            count: selectedIds?.length,
                        },
                    }),
                })
            );
            onCancel();
        }
    };

    const { data, onClick, onCancel, isVisible, onConfirm } = useConfirm(handleDashboardCreation as any);

    useImperativeHandle(
        ref,
        () => ({
            handleModalAction: (type: boolean, selectedData: any) => handleModalAction(type, selectedData),
        }),
        []
    );

    const handleModalAction = (type: boolean, selectedData?: any): void => {
        if (type) {
            onClick(selectedData);
            return;
        }
        onCancel();
    };
    const createDashboardActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: onCancel,
        },
        {
            key: 'save',
            label: <CustomTransComponent translationKey={'COMMON:APPLY'} />,
            variant: 'contained',
            disabled: isUpdatingDashboard || !selectedIds?.length,
            handleClick: onConfirm,
        },
    ];

    return (
        <CustomModal
            isOpen={isVisible}
            isLoading={isUpdatingDashboard}
            type="primary"
            actions={createDashboardActions}
            header={<CustomTransComponent translationKey={'COMMON:ASSOCIATE_DEVICES'} />}
        >
            <Stack py={2}>
                <Typography variant="body1" fontWeight={'600'} mb={2}>
                    <CustomTransComponent
                        translationKey={'DASHBOARDS:ASSOCIATE_DEVICES'}
                        replace={{ name: data.name }}
                    />
                </Typography>
                <FormControl variant={'filled'} required fullWidth>
                    <TextField
                        hiddenLabel
                        id="searchKey"
                        value={searchKey ?? ''}
                        onChange={(e: any): void => {
                            setSearchKey(e.target.value);
                        }}
                        placeholder={t('COMMON:SEARCH_PLACEHOLDER')}
                        size="small"
                        InputProps={{
                            startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                        }}
                    />
                    <Hierarchy
                        isLoading={!groupsData || isLoading}
                        data={filteredRecords?.length ? filteredRecords : groupsData}
                        hasChild={(treeItem: any): boolean => treeItem?.groupCount}
                        labelKey={'name'}
                        hierarchyMappingData={hierarchyMappingData}
                        loadingNode={loadingHierarchyNode}
                        handleTreeNodeSelection={(parentNode: any): any => {
                            groupSelectionHandler(parentNode?.id);
                        }}
                        filters={[
                            {
                                key: 'type',
                                operator: '!==',
                                value: 'GATEWAY',
                                action: 'hidden',
                            },
                            {
                                key: 'type',
                                operator: '!==',
                                value: 'GROUP',
                                action: 'disableCheck',
                            },
                        ]}
                        autoChildSelection={true}
                        loadMore={handleFetchHierarchy}
                        loadingSkeleton={<GroupHierarchySkeleton row={1} />}
                        selectedNodes={selectedIds}
                        selectionType={'checkbox'} // radio, checkbox, node
                    />
                </FormControl>
            </Stack>
        </CustomModal>
    );
});
