import React from 'react';
import Close from '@mui/icons-material/Close';
import FilterListOff from '@mui/icons-material/FilterListOff';
import Search from '@mui/icons-material/Search';
import { Box, Button, Chip, Divider, FormControl, IconButton, Paper, Stack, Typography } from '@mui/material';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { toFirstLetterUpperCase } from 'utils/helpers';
import { useParams } from 'react-router-dom';
import { CustomTransComponent } from 'components';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';

import { usePayloadActions } from '@fiji/common/src/hooks/usePayloadActions';
import { useTheme } from '@mui/material/styles';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';

export const DocumentFilter = ({
    handleOpenFilter,
    payload,
    setPayload,
    customDate,
    setCustomDate,
}: any): JSX.Element => {
    const [cleanPayload] = usePayloadActions();
    const theme: any = useTheme();
    const { type, typeId, categoryId } = useParams();
    const currentOrg = useTypedSelector(selectedOrg);

    const [groupsData, setGroupsData] = React.useState();

    const [filters, setFilters] = React.useState<any>({ page: 0, size: 100 });

    const closeHandler = (): void => {
        handleOpenFilter(false);
    };
    const resetFilters = (key: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(filters?.filters));
        delete payloadClone[key];
        if (key === 'date') {
            setCustomDate([null, null]);
        }
        setFilters({ filters: payloadClone });
    };

    const handleResetAllFilters = (): void => {
        setFilters({});
        setCustomDate([null, null]);
        setPayload((prev: any) => ({ page: prev.page, size: prev.size }));
        closeHandler();
    };

    const [getHeirarchyData, { data: groupHierarchy }] = useGetAllGroupsMutation({
        fixedCacheKey: 'cachedAllGroupData',
    });

    React.useEffect(() => {
        if (!currentOrg?.defaultGroupAccess && currentOrg?.id) {
            getHeirarchyData(currentOrg?.id) as any;
        }
    }, [currentOrg]);
    React.useEffect(() => {
        if (!groupsData) {
            setGroupsData(groupHierarchy?.data?.records);
        }
    }, [groupHierarchy]);

    const payloadHandler = (id: string, data?: any): void => {
        const payloadClone = JSON.parse(JSON.stringify(filters));
        if (id !== 'documentTypes') {
            payloadClone.filters[id] = data;
        } else {
            const docTypeIndex = payloadClone?.filters?.documentTypes?.findIndex((docType: any) => docType === data);
            if (docTypeIndex === undefined) {
                if (payloadClone?.filters === undefined) {
                    payloadClone.filters = { documentTypes: [data] };
                } else {
                    payloadClone.filters.documentTypes = [data];
                }
            } else if (docTypeIndex === -1) {
                payloadClone?.filters?.documentTypes?.push(data);
            } else {
                payloadClone?.filters?.documentTypes?.splice(docTypeIndex, 1);
            }
        }
        setFilters(payloadClone);
    };

    const submitHandler = (): void => {
        const documentPayload = {
            filters: {
                ...filters.filters,
                from: new Date(customDate?.[0])?.getTime() === 0 ? null : new Date(customDate?.[0])?.getTime(),
                to: new Date(customDate?.[1])?.getTime() === 0 ? null : new Date(customDate?.[1])?.getTime(),
            },
        };
        if (filters?.filters?.groupsAndDevices?.groupIds?.length) {
            documentPayload.filters['groupIds'] = filters?.filters?.groupsAndDevices.groupIds ?? [];
        }
        if (filters?.filters?.groupsAndDevices?.deviceIds?.length) {
            documentPayload.filters['deviceIds'] = filters?.filters?.groupsAndDevices.deviceIds ?? [];
        }

        delete documentPayload?.filters?.['date'];
        delete documentPayload?.filters?.['groupsAndDevices'];

        const cleanedPayload = cleanPayload(documentPayload?.filters);

        setPayload({ ...payload, filters: { ...cleanedPayload } });
        setFilters({});
        closeHandler();
    };

    React.useEffect(() => {
        setFilters({
            ...payload,
            filters: {
                ...(type === 'device' && typeId && { deviceIds: [typeId] }),
                ...(type === 'group' && typeId !== currentOrg?.id && { groupIds: [typeId] }),
                documentTypes: [categoryId],
                ...payload?.filters,
            },
        });
    }, []);

    return (
        <Paper className="documents-filter">
            <Box className="padding-16 flex-center-between">
                <Box className="align-items-center">
                    <IconButton id="close" onClick={closeHandler}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6" fontSize={'16px'} fontWeight={'600'}>
                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.TITLE'} />
                    </Typography>
                </Box>
                <IconButton onClick={handleResetAllFilters}>
                    <FilterListOff />
                </IconButton>
            </Box>
            <Divider />
            {filters?.filters && Boolean(Object?.keys(filters?.filters)?.length) && (
                <Stack className="padding-16" flexDirection={'row'} alignItems={'center'} flexWrap={'wrap'}>
                    {Object?.keys(cleanPayload(filters?.filters))?.map(
                        (key: string) =>
                            key !== 'documentTypes' &&
                            key !== 'deviceIds' &&
                            key !== 'groupIds' && (
                                <Chip
                                    key={key}
                                    label={toFirstLetterUpperCase(key)}
                                    onDelete={(): void => resetFilters(key)}
                                    variant="outlined"
                                    className="custom-filter-search-btn"
                                />
                            )
                    )}
                </Stack>
            )}
            <Box className="border-bottom-1">
                <Box className="padding-x-16 padding-y-8">
                    <Typography variant="h6" color="primary" fontSize={'16px'} fontWeight={'600'} pb={'20px'}>
                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'} />
                    </Typography>

                    <Box className="margin-bottom-20">
                        <FormControl className="w-100">
                            <Box className="position-relative">
                                <InputWithCustomPlaceholder
                                    id="fileName"
                                    value={filters?.filters?.fileName ?? ''}
                                    // onChange={(e: any): void => {
                                    //     setPayload((prev: any) => ({ ...prev, name: e.target.value }));
                                    // }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        payloadHandler('fileName', e.target.value)
                                    }
                                    variant="outlined"
                                    placeholder={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'}
                                    className="w-100"
                                    InputProps={{
                                        endAdornment: (
                                            <Search
                                                style={{
                                                    color: '#777',
                                                }}
                                                id="search"
                                            />
                                        ),
                                    }}
                                />
                                {/* <TextField
                                    id="fileName"
                                    value={filters?.filters?.name ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        payloadHandler('name', e.target.value)
                                    }
                                    variant="outlined"
                                    placeholder={
                                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'} />
                                    }
                                    className="w-100"
                                    InputProps={{
                                        endAdornment: (
                                            <Search
                                                style={{
                                                    color: '#777',
                                                }}
                                                id="search"
                                            />
                                        ),
                                    }}
                                /> */}
                            </Box>
                        </FormControl>
                    </Box>
                </Box>
                <Divider />
                <Box className="padding-16">
                    <Typography variant="h6" color="primary" fontSize={'16px'} fontWeight={'600'} pb={'20px'}>
                        <CustomTransComponent translationKey={'COMMON:DATE'} />
                    </Typography>
                    <Box
                        sx={{
                            '& .MuiDateRangeCalendar-root': { alignSelf: 'center' },
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDateRangePicker
                                value={customDate ?? [null, null]}
                                onChange={(data: any): void => {
                                    payloadHandler('date', data);
                                    setCustomDate(data);
                                }}
                                slotProps={{
                                    toolbar: { hidden: true },
                                    leftArrowIcon: { color: 'primary' },
                                    rightArrowIcon: { color: 'primary' },
                                    actionBar: { actions: [] },
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Divider />
            </Box>

            <Box className="padding-16">
                <Button
                    id="apply-filter"
                    variant="contained"
                    sx={{
                        width: '100%',
                        backgroundColor: theme?.palette?.primary?.main,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                    onClick={submitHandler}
                >
                    <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.APPLY_FILTERS'} />
                </Button>
            </Box>
        </Paper>
    );
};
