import React from 'react';
import { Select as SelectPicker, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import { useStyles } from './styles';

type FormInputProps = {
    id: string;
    name: string;
    label: string;
    options: any;
    control: any;
    rules: any;
    defaultValue: any;
    disabled: boolean;
};

const Select = ({ id, name, label, options, control, rules, defaultValue, disabled }: FormInputProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <FormControl variant={'filled'} className={classes.formControl} fullWidth>
            <InputLabel id={name}>{label}</InputLabel>
            <Controller
                rules={rules}
                render={({ field: { onChange, value } }): JSX.Element => (
                    <SelectPicker id={id} labelId={name} value={value} onChange={onChange} disabled={disabled}>
                        {options.map((device: any) => (
                            <MenuItem key={`key${device.key}`} value={device.key}>
                                {device.value}
                            </MenuItem>
                        ))}
                    </SelectPicker>
                )}
                control={control}
                name={name}
                defaultValue={defaultValue || ''}
            />
        </FormControl>
    );
};

export default Select;
