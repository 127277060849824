import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createUser: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/invite`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Users'] : []),
        }),
        updateUser: builder.mutation({
            query: ({ userId, payload }) => ({
                url: `/iam/v1/users/${userId}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: (result, error): any => (!error ? ['UserById'] : []),
        }),

        approveUser: builder.mutation({
            query: ({ payload }) => ({
                url: `/iam/v1/users/request/approve `,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result, error): any => (!error ? ['UserById'] : []),
        }),
        getRealmUsers: builder.query<any, any>({
            query: ({ realmId }) => ({
                url: `iam/v1/realms/${realmId}/users`,
                method: 'GET',
            }),
        }),
        getUserById: builder.query({
            query: ({ id, realmPrefix }) => ({
                url: `/iam/v1/users/${id}`,
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            providesTags: ['UserById'],
        }),

        getAllUsers: builder.query<any, any>({
            query: ({ body, realmPrefix, organizationId }) => {
                const bodyClone = JSON.parse(JSON.stringify(body));
                if (bodyClone?.currentOrg) {
                    delete bodyClone.currentOrg;
                }
                return {
                    url: 'iam/v1/users/filter',
                    method: 'POST',
                    body: bodyClone,
                    headers: realmPrefix && { 'x-realm': realmPrefix, organizationId: organizationId },
                };
            },
            providesTags: ['Users'],
        }),
        getMobileUsers: builder.query<any, any>({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: 'iam/v1/users/filter',
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
        }),
        enableOrDisableUser: builder.mutation({
            query: ({ body, headers }) => ({
                url: `/iam/v1/users/access`,
                method: 'PATCH',
                body: body,
                headers: headers && headers,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Users'] : []),
        }),
        resendInvitation: builder.mutation({
            query: ({ body, headers }) => ({
                url: `/iam/v1/users/invite/resend`,
                method: 'POST',
                body: body,
                headers: headers && headers,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Users'] : []),
        }),
        deleteUser: builder.mutation({
            query: ({ body, headers }: any) => ({
                url: `/iam/v1/users`,
                method: 'DELETE',
                body: body,
                headers: headers && headers,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Users'] : []),
        }),
        updateUserProfile: builder.mutation({
            query: (body) => {
                const newBody = JSON.parse(JSON.stringify(body ?? {}));
                if (newBody?.realmPrefix) delete newBody?.realPrefix;
                return {
                    url: `/iam/v1/users/me/profile`,
                    method: 'PATCH',
                    body: newBody,
                    ...(body?.realmPrefix && { headers: { 'x-realm': body?.realmPrefix } }),
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['Profile'] : []),
        }),
        getApplicationConfig: builder.query({
            query: ({ headers }: { headers?: any }) => ({
                method: 'POST',
                url: `/iam/v1/users/application/config`,
                ...(headers ? { headers } : {}),
            }),
        }),
        deleteSelfUser: builder.mutation({
            query: (body) => ({
                url: `iam/v1/users/me`,
                method: 'DELETE',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'Profile', 'userRoles'] : []),
        }),
        getEula: builder.query<any, any>({
            query: ({ realmPrefix, locale }) => ({
                url: `iam/v1/users/eula/profile`,
                headers: {
                    ...(realmPrefix && { 'x-realm': realmPrefix }),
                    ...(locale && { Locale: locale }),
                },
            }),
        }),
        getRegisteredUserEula: builder.query<any, any>({
            query: ({ realmPrefix, locale }) => ({
                url: `iam/v1/users/eula/registered/profile`,
                headers: {
                    ...(realmPrefix && { 'x-realm': realmPrefix }),
                    ...(locale && { Locale: locale }),
                },
            }),
            providesTags: ['UserEula'],
        }),
        acceptEula: builder.mutation({
            query: ({ realmPrefix, locale }) => ({
                url: 'iam/v1/users/eula/accept',
                method: 'POST',
                headers: {
                    ...(realmPrefix && { 'x-realm': realmPrefix }),
                    ...(locale && { Locale: locale }),
                },
            }),
            invalidatesTags: (result, error): any => (!error ? ['UserEula'] : []),
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetAllUsersQuery,
    useGetMobileUsersQuery,
    useUpdateUserProfileMutation,
    useCreateUserMutation,
    useGetRealmUsersQuery,
    useGetUserByIdQuery,
    useEnableOrDisableUserMutation,
    useResendInvitationMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,
    useApproveUserMutation,
    useDeleteSelfUserMutation,
    useGetApplicationConfigQuery,
    useGetEulaQuery,
    useGetRegisteredUserEulaQuery,
    useAcceptEulaMutation,
} = userApi;
