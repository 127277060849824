import React from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/styles';
import { Dialog, DialogTitle, Typography, DialogActions, DialogContent, Button, Grid } from '@mui/material';
import { useNavigationBack } from 'hooks';

import { CustomTransComponent } from 'components/CustomTransComponent';
/**
 * The DiscardModalProps type is used in a TypeScript React component and includes properties for
 * visibility and a modal handler function.
 * @property {boolean} isVisible - The `isVisible` property is a boolean value that determines whether
 * the discard modal is visible or not. If `isVisible` is `true`, the modal is visible. If `isVisible`
 * is `false`, the modal is hidden.
 * @property modalHandler - The `modalHandler` property is a function that takes a boolean parameter
 * `action` and does something with it. It is used to handle the action performed on the modal, such as
 * closing it or confirming a discard action.
 */
type DiscardModalProps = {
    isVisible: boolean;
    modalHandler: (action: boolean) => void;
};

/**
 * The `DiscardModal` function is a TypeScript React component that renders a dialog box with a title,
 * message, and two buttons for canceling or discarding changes.
 * @param {DiscardModalProps}  - - `isVisible`: a boolean value indicating whether the modal is visible
 * or not.
 * @returns The code is returning a JSX element, specifically a `Dialog` component with various child
 * components such as `DialogTitle`, `DialogContent`, and `DialogActions`.
 */
export const DiscardModal = ({ isVisible, modalHandler }: DiscardModalProps): JSX.Element => {
    const theme: any = useTheme();
    const classes = useStyles(theme);
    const navigationBack = useNavigationBack();

    return (
        <Dialog
            open={isVisible}
            onClose={(): void => modalHandler(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.muiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant={'h6'}>
                    {' '}
                    <CustomTransComponent translationKey={'COMMON:DISCARD_CHANGES_TITLE'} />
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body1'} mb={1}>
                    <CustomTransComponent translationKey={'COMMON:ACTION_UNDONE_WARNING_DEVICE'} />
                </Typography>
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                <Grid container justifyContent={'flex-end'} spacing={2}>
                    <Grid item>
                        <Button
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                    color: theme?.palette?.primary?.main,
                                },
                            }}
                            variant={'outlined'}
                            onClick={(): void => modalHandler(false)}
                            data-test="discard-cancel-btn"
                        >
                            <CustomTransComponent translationKey={'COMMON:GO_BACK'} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            data-test="discard-btn"
                            sx={{
                                // backgroundColor: theme?.palette?.primary?.[50],
                                backgroundColor: theme?.palette?.primary?.main,
                                // color: '#fff',
                                // color: theme?.palette?.primary?.main,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.main,
                                },
                            }}
                            variant={'contained'}
                            // className={classes.discardButton}
                            onClick={(): void => navigationBack()}
                        >
                            <CustomTransComponent translationKey={'COMMON:CONTINUE'} />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
