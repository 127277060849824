import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Stack, Breadcrumbs, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Route } from '../router/routes';
import { Spacer } from '@brightlayer-ui/react-components';
import Close from '@mui/icons-material/Close';
import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HeaderActions } from './HeaderActions';
import { useNavigationBack } from 'hooks';
import { CustomTransComponent } from './';
import { setDashboardWidgetIds } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';

/* The `InternalPageHeader` component is a React functional component that renders a header for an
internal page. It takes in three props: `nestedRoutesList`, `title`, and `page`. */
export const InternalPageHeader = (props: {
    nestedRoutesList: Route[];
    title: string;
    page: JSX.Element;
    extraProps: Route;
    isServiceAdmin?: boolean;
}): JSX.Element => {
    const { nestedRoutesList, title, page, extraProps, isServiceAdmin } = props;
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { widgetId } = useParams();
    const navigationBack = useNavigationBack();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const isSuperRealm = useTypedSelector((state: RootState) => state.common.isSuperRealm);
    const isAdminRealm = useTypedSelector((state: RootState) => state.common.isAdminRealm);
    const headerColorChange = useSelector((state: any) => state.common.headerColor);
    const timelineStatusColor = useSelector((state: any) => state.common.timelineStatusColor);
    const { t } = useTranslation();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const NO_ORGANIZATION =
        (!currentOrg?.id && location.pathname === `/${currentRealmName}/organization`) ||
        location.pathname.indexOf('orgmanagementdetails') !== -1;
    const appHeaderSubTitle = useTypedSelector((state) => state.common.appHeaderSubTitle);
    const pageHeaderData = useTypedSelector((state) => state.common.pageHeader);
    const appHeaderTitle = useTypedSelector((state) => state.common.appHeaderTitle);
    const widgetLoader = useTypedSelector((state) => state.widgetConfiguration.isLoading);
    const widgetNavigatorStack = useTypedSelector((state) => state.widgetConfiguration.saveAsCount);

    function redirectionHandler(): void {
        if (location.state?.assignType) {
            dispatch(setDashboardWidgetIds(widgetId));
        }
        handleNavigateBack();
    }

    const handleNavigateBack = (): void =>
        widgetNavigatorStack > 1 ? navigationBack(widgetNavigatorStack) : navigationBack();

    const getColors = (color: string): any => {
        switch (color) {
            case 'red':
                return Colors.red;
            case 'orange':
                return Colors.orange;
            default:
                return Colors.blue;
        }
    };

    const getServiceAdminTextColor = (): any => {
        if (isServiceAdmin) {
            return 'text-white';
        } else if (
            (currentOrg?.id || location.pathname === `/${currentRealmName}/myProfile` || isSuperRealm) &&
            !timelineStatusColor
        ) {
            return 'text-secondary';
        }
        return 'text-white';
    };

    const getIconColor = (): string => {
        if (timelineStatusColor) {
            return '#FFF';
        }
        return extraProps?.iconColor ?? '#424e54';
    };

    const getLeftIcon = (): any =>
        (currentOrg?.id || location.pathname === `/${currentRealmName}/myProfile` || isSuperRealm || isAdminRealm) && (
            <IconButton onClick={widgetId ? redirectionHandler : handleNavigateBack}>
                {extraProps?.crossIcon ? (
                    <Close className="black-500" />
                ) : (
                    <ArrowBackIcon
                        style={{
                            color: getIconColor(),
                        }}
                    />
                )}
            </IconButton>
        );

    const getToolbarClassName = (): string => {
        let className = 'padding-x-16 min-height-56 ';
        if (isServiceAdmin) {
            className += 'bg-blue-500';
        } else if (
            (currentOrg?.id || location.pathname === `/${currentRealmName}/myProfile` || isSuperRealm) &&
            !timelineStatusColor
        ) {
            className += 'bg-white';
        }
        return className;
    };

    const getSubtitle = (): any => {
        if (extraProps?.subTitle) {
            return <CustomTransComponent translationKey={extraProps?.subTitle} />;
        } else if (appHeaderSubTitle) {
            return appHeaderSubTitle;
        }
        return currentOrg?.name ?? '';
    };

    const getSubtitleJSX = (): JSX.Element => {
        if (extraProps?.noSubtitle) {
            return <></>;
        }
        return (
            <Typography variant={'body1'} color={timelineStatusColor || isServiceAdmin ? '#fff' : Colors.black[500]}>
                {getSubtitle()}
            </Typography>
        );
    };

    const getNoBreadcrumbsTitle = (): any => {
        if (NO_ORGANIZATION) {
            return 'No Organization';
        } else if (pageHeaderData?.title) {
            return pageHeaderData?.title;
        } else if (appHeaderTitle) {
            return appHeaderTitle;
        }
        return <CustomTransComponent translationKey={extraProps?.title} />;
    };

    const getNoBreadcrumbsHeader = (): any => {
        if (extraProps?.path?.includes('editWidget')) {
            return (
                <>
                    {widgetLoader ? (
                        <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={200}
                            height={15}
                            sx={{ marginBottom: '10px', marginTop: '10px' }}
                        />
                    ) : (
                        pageHeaderData?.title ?? ''
                    )}
                </>
            );
        }
        return getNoBreadcrumbsTitle();
    };

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
            }}
            minHeight={'100vh'}
            position={'relative'}
        >
            <AppBar
                className="position-sticky"
                sx={
                    extraProps?.noShadowedHeader
                        ? { boxShadow: 'none', color: 'inherit', pr: `0px !important`, zIndex: 1299 }
                        : {
                              color: 'inherit',
                              backgroundColor: `${timelineStatusColor || getColors(headerColorChange)[500]}`,
                              pr: `0px !important`,
                              zIndex: 1299,
                          }
                }
            >
                <Toolbar className={getToolbarClassName()}>
                    {getLeftIcon()}
                    <Stack pl={3} direction={'column'}>
                        {extraProps?.noBreadcrumbs ? (
                            <Typography
                                key={extraProps.path}
                                variant={'h6'}
                                color={'inherit'}
                                className={`${
                                    currentOrg?.id ||
                                    location.pathname === `/${currentRealmName}/myProfile` ||
                                    isSuperRealm
                                        ? 'text-secondary'
                                        : 'text-white'
                                }`}
                            >
                                {getNoBreadcrumbsHeader()}
                            </Typography>
                        ) : (
                            <Breadcrumbs separator={'/'}>
                                {nestedRoutesList?.map(
                                    (route: Route): any =>
                                        route.title && (
                                            <Typography
                                                key={`${route.path}-breadcrumb`}
                                                variant={'h6'}
                                                color={'inherit'}
                                                onClick={(): void => {
                                                    navigate(`/${currentRealmName}/${route.path}`);
                                                }}
                                                className={getServiceAdminTextColor()}
                                            >
                                                {appHeaderTitle ?? (
                                                    <CustomTransComponent translationKey={route.title} />
                                                )}
                                            </Typography>
                                        )
                                )}
                            </Breadcrumbs>
                        )}

                        <Typography variant={'body1'} color={extraProps?.fontColor ?? Colors.black[500]}>
                            {t(title)}
                        </Typography>
                        {getSubtitleJSX()}
                    </Stack>
                    <Spacer />
                    {NO_ORGANIZATION && <HeaderActions disabled={!currentOrg?.id} />}
                </Toolbar>
            </AppBar>
            {page}
        </Box>
    );
};
