import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { createNumArray } from 'utils/helpers';

export const EventDetailsSkeleton = (): JSX.Element => (
    <>
        {createNumArray(3)?.map((_item: any) => (
            <Accordion key={`parent${_item}`} defaultExpanded>
                <AccordionSummary aria-controls="panel1bh-content" id="expand" className=" border-bottom-1">
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        className="flex-100"
                        pl={2}
                    >
                        <Skeleton animation="wave" variant="rounded">
                            <Typography variant="body2" fontWeight={'600'} fontSize={'14px'}>
                                primary status
                            </Typography>
                        </Skeleton>

                        <Stack direction={'row'} className="flex-60" spacing={1}>
                            {createNumArray(2).map((_subItem: any, subIndex: number) => (
                                <Skeleton
                                    key={`subItem${_subItem}`}
                                    variant="rounded"
                                    animation="wave"
                                    {...(subIndex === 1 && {
                                        sx: {
                                            marginLeft: '116px !important',
                                        },
                                    })}
                                >
                                    <Typography variant="body2" fontSize={'12px'}>
                                        At Time of Event: Random Date 00
                                    </Typography>
                                </Skeleton>
                            ))}
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails className="padding0">
                    {createNumArray(2).map((_channel: any, channelIndex: any) => (
                        <Stack
                            key={`channel${_channel}`}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className={`flex-100 ${channelIndex < 1 ? 'border-bottom-1' : ''}`}
                            pl={2}
                            py={1}
                        >
                            <Stack direction={'row'} alignItems={'center'} className="flex-30" spacing={1}>
                                <Skeleton animation="wave" variant="circular" height={25} width={25} />
                                <Skeleton animation="wave" variant="rounded">
                                    <Typography variant="body2" fontSize={'14px'}>
                                        channel?.displayName
                                    </Typography>
                                </Skeleton>
                            </Stack>
                            <Stack direction={'row'} className="flex-60" spacing={1}>
                                {createNumArray(2).map((_channelValue: any) => (
                                    <Skeleton key={`value${_channelValue}`} animation="wave" variant="rounded">
                                        <TextField
                                            id="delay"
                                            variant="filled"
                                            className="delayed-input-class channel-value-textfield "
                                            sx={{ width: 300 }}
                                        />
                                    </Skeleton>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </AccordionDetails>
            </Accordion>
        ))}
    </>
);
