import { InfoListItem } from '@brightlayer-ui/react-components';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useExportTrendsMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useConfirm } from '@fiji/common/src/hooks';
import { getTimeInFromTo } from '@fiji/common/src/utils/helpers';
import { Download, Send } from '@mui/icons-material';
import {
    Stack,
    Typography,
    FormControl,
    Select,
    MenuItem,
    List,
    Checkbox,
    Button,
    CircularProgress,
} from '@mui/material';
import { CustomModal, CustomTransComponent } from 'components';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { addRemoveArrayHandler } from 'pages/AdvanceExportTrends/ExportMethod';
import MultiInput from 'pages/AdvanceExportTrends/MultiInput';
import React from 'react';
import { useDispatch } from 'react-redux';
import { downloadFileFromLink, getUTCTimestamp } from 'utils/helpers';

const initialExportPayload: any = { emails: [], fileType: 'CSV', types: [] };

export const ExportModal = React.forwardRef((props: any, ref) => {
    const modalRef = React.useRef<any>(null);
    const dispatch = useDispatch();
    const [snackId, setSnackId] = React.useState(0);

    const [inputValue, setInputValue] = React.useState('');
    const [exportPayload, setExportPayload] = React.useState(initialExportPayload);

    const [exportTrends, { data: exportedData, isLoading: isExporting, isSuccess, isError }] =
        useExportTrendsMutation();
    const submitHandler = async (payload: any): Promise<void> => {
        const clone = JSON.parse(
            JSON.stringify({
                ...exportPayload,
                exportRequests: payload?.payload,
                chartType: payload?.chartType,
            })
        );

        if (payload?.duration) {
            const newDate: any = getTimeInFromTo(payload?.[0]?.duration ?? 0, 'h');
            clone['from'] = getUTCTimestamp(newDate?.from);
            clone['to'] = getUTCTimestamp(newDate?.to);
        } else if (payload?.from) {
            clone['from'] = getUTCTimestamp(payload?.from);
            clone['to'] = getUTCTimestamp(payload?.to);
        }
        if (!exportPayload?.types?.includes?.('EMAIL')) {
            delete clone?.emails;
        }
        delete clone.fileType;
        const { error }: any = await exportTrends({
            body: clone,
            params: { type: exportPayload?.fileType },
        });
        if (!error) {
            setExportPayload(initialExportPayload);
            onCancel();
        }
    };

    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(submitHandler as any);

    React.useImperativeHandle(ref, () => ({
        onClick: (action: boolean): void => onClick(action),
    }));

    const action = (snackbarKey: any): JSX.Element => (
        <Button
            variant="text"
            sx={{ color: '#80BDE0' }}
            name={snackbarKey}
            onClick={() => {
                closeSnackbar(+snackbarKey);
            }}
        >
            Ok
        </Button>
    );

    const actionDownload = (snackbarKey: any): JSX.Element => (
        <Button
            variant="text"
            sx={{ color: '#80BDE0' }}
            name={snackbarKey}
            onClick={() => {
                closeSnackbar(+snackbarKey);
                if (exportedData?.data) downloadFileFromLink(exportedData?.data, 'trends');
                else
                    dispatch(
                        setMessageContent({
                            isOpen: true,
                            message: 'No data found',
                            duration: 3000,
                        })
                    );
            }}
        >
            Ok
        </Button>
    );

    React.useEffect(() => {
        const snackbarProps: any = {
            variant: 'notificationWithIcon',
            subtitle: 'Please do not close the window',
            Icon: <CircularProgress size={20} />,
            autoHideDuration: null,
            notificationWithAction: false,
            setId: setSnackId,
        };
        if (isExporting) {
            enqueueSnackbar('Exporting', snackbarProps);
        }
    }, [isExporting]);

    React.useEffect(() => {
        const snackbarProps: any = {
            variant: 'notificationWithIcon',
            subtitle: '',
            autoHideDuration: 60000,
            Icon: <Download />,
            notificationWithAction: true,
            notificationAction: actionDownload,
        };
        const snackbarPropsEmail: any = {
            variant: 'notificationWithIcon',
            subtitle: 'Email sent successfully',
            autoHideDuration: 3000,
            Icon: <Send />,
            notificationWithAction: true,
            notificationAction: action,
        };

        if (isSuccess || isError) {
            closeSnackbar(+snackId);
        }
        if (isSuccess) {
            if (exportPayload?.types?.includes('EMAIL')) {
                enqueueSnackbar('Sent', snackbarPropsEmail);
            }
            if (exportPayload?.types?.includes('DOWNLOAD')) {
                enqueueSnackbar('Ready to download', snackbarProps);
            }
        }
    }, [isError, isSuccess]);

    const getIsDisabled = (): boolean => {
        if (exportPayload?.fileType) {
            if (exportPayload?.types?.includes?.('DOWNLOAD') && !exportPayload?.types?.includes?.('EMAIL')) {
                return false;
            } else if (exportPayload?.types?.includes?.('EMAIL')) {
                return !exportPayload?.emails?.length;
            }
            return true;
        }
        return true;
    };

    const handleTagDelete = (index: number): void => {
        const exportPayloadClone = JSON.parse(JSON.stringify(exportPayload));
        exportPayloadClone?.emails?.splice(index, 1);
        setExportPayload(exportPayloadClone);
    };

    const ExportTrendActions = [
        {
            key: 'cancel',
            label: 'Cancel',
            // close: true,
            variant: 'outlined',
            handleClick: (): void => {
                setExportPayload(initialExportPayload);
                onCancel();
            },
        },
        ...(props?.advanceAction
            ? [
                  {
                      key: 'ax',
                      label: 'Advanced..',
                      stackedKey: 'stacked',
                      variant: 'outlined',
                      handleClick: (): void => props?.advanceAction(exportPayload?.fileType),
                  },
              ]
            : []),
        {
            key: 'export',
            label: 'Export',
            ...(props?.advanceAction && { stackedKey: 'stacked' }),
            disabled: getIsDisabled() || isExporting,
            variant: 'contained',
            handleClick: onConfirm,
        },
    ];

    const handleKeyDown = (e: any): void => {
        if (e.code === 'Enter' && exportPayload?.emails?.length < 6) {
            const tagValue = e.target.value;
            setExportPayload((prev: any) => ({
                ...prev,
                emails: [...(prev?.emails ?? []), tagValue],
            }));
            setInputValue('');
        }
    };
    return (
        <CustomModal
            ref={modalRef}
            isOpen={isVisible}
            isLoading={isExporting}
            type="primary"
            isStacked
            actions={ExportTrendActions as any}
            noDividers
            actionsDivider
            header={props?.header}
            footerClass={props?.advanceAction ? 'space-between-footer' : ''}
        >
            <>
                <Stack spacing={2} my={2}>
                    <Typography variant="body1" mb={2}>
                        <CustomTransComponent translationKey={'AUDIT_LOGS:FILE_FORMAT'} />
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            className="custom-select"
                            labelId="demo-simple-select-helper-label"
                            id="chooseFileType"
                            label="time"
                            variant="filled"
                            fullWidth
                            disableUnderline
                            value={exportPayload?.fileType}
                            onChange={(e: any): void =>
                                setExportPayload((prev: any) => ({ ...prev, fileType: e?.target?.value }))
                            }
                        >
                            <MenuItem value={'CSV'} id="csv">
                                <CustomTransComponent translationKey={'AUDIT_LOGS:FILE_OPTIONS.CSV'} />
                            </MenuItem>
                            <MenuItem value={'EXCEL'} id="excel">
                                <CustomTransComponent translationKey={'AUDIT_LOGS:FILE_OPTIONS.EXCEL'} />
                            </MenuItem>
                            <MenuItem value={'PDF'} id="pdf">
                                <CustomTransComponent translationKey={'AUDIT_LOGS:FILE_OPTIONS.PDF'} />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack spacing={2} mt={3}>
                    <Typography variant="body1" mb={2}>
                        <CustomTransComponent translationKey={'AUDIT_LOGS:FILE_LOCATION'} />
                    </Typography>
                    <List disablePadding>
                        <InfoListItem
                            disablePadding
                            title={
                                <Typography variant="body1">
                                    <CustomTransComponent translationKey={'COMMON:DOWNLOAD'} />
                                </Typography>
                            }
                            icon={
                                <Checkbox
                                    id="download"
                                    checked={exportPayload?.types?.includes('DOWNLOAD')}
                                    onChange={(): void =>
                                        setExportPayload((prev: any) => ({
                                            ...prev,
                                            types: addRemoveArrayHandler('DOWNLOAD', exportPayload?.types),
                                        }))
                                    }
                                />
                            }
                        />
                        <InfoListItem
                            disablePadding
                            title={
                                <Typography variant="body1">
                                    <CustomTransComponent translationKey={'COMMON:SEND_BY_MAIL'} />
                                </Typography>
                            }
                            icon={
                                <Checkbox
                                    id="email"
                                    checked={exportPayload?.types?.includes('EMAIL')}
                                    onChange={(): void =>
                                        setExportPayload((prev: any) => ({
                                            ...prev,
                                            types: addRemoveArrayHandler('EMAIL', exportPayload?.types),
                                        }))
                                    }
                                />
                            }
                        />
                    </List>
                </Stack>
                {exportPayload?.types?.includes?.('EMAIL') && (
                    <MultiInput
                        value={exportPayload?.emails ?? []}
                        handleKeyDown={handleKeyDown}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        placeholder="Email Address"
                        isDisabled={false}
                        handleTagDelete={handleTagDelete}
                    />
                )}
            </>
        </CustomModal>
    );
});
