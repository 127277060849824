import React, { useEffect } from 'react';
import { CustomTabs, CustomTransComponent, Loader } from '../../components';
import { Box } from '@mui/material';
import Dashboard from '@mui/icons-material/Dashboard';
import Edit from '@mui/icons-material/Edit';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    CustomTabsSkeleton,
    EditDashboardDetails,
    MappedTabs,
    SelectDashboardModal,
} from '../DashboardManagement/Common';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { DeviceMenu } from './Devices/DeviceOptions';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { CustomMenu } from 'components/CustomMenu';

import { useGetGroupDetailsQuery } from '@fiji/common/src/features/group/groupApi';
import {
    setAppHeaderSubTitle,
    setAppHeaderTitle,
    setMqttConnectionStatus,
    setMqttCredentials,
    setSelectedNode,
} from '@fiji/common/src/features/common/commonSlice';
import { DriveFileRenameOutline } from '@mui/icons-material';
import {
    useGetActiveDashboardsQuery,
    useGetAllTabsQuery,
} from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { closeMqttConnection, connectMQTT } from '@fiji/common/src/mqtt_connection/mqttConnection';
import { useGetMQTTCredentialsQuery } from '@fiji/common/src/features/mqtt/mqttApi';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { decryptAES } from 'utils/helpers';

export const GroupOverview = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const editRef: any = React.useRef(null);
    const navigate = useNavigate();
    const { groupId } = useParams();
    const permission = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permission);
    const location = useLocation();
    const canUpdateSystemDashboard = hasPermission('edit-system-dashboards');
    const canUpdateUserDashboard = hasPermission('edit-user-dashboards');
    const selectModalRef = React.useRef<any>(null);
    const currentOrg = useTypedSelector(selectedOrg);
    React.useEffect(() => () => dispatch(setAppHeaderSubTitle(undefined)));
    const isOrgInvalidated = useTypedSelector((state) => state.org.isOrgInvalidated);
    const currentRealmName = useTypedSelector((rootState: any) => rootState.common.selectedRealm);
    const activeDashboardId = useTypedSelector((rootstate: any) => rootstate.commonDashboard.dashboardId);
    const { data: groupData } = useGetGroupDetailsQuery(groupId, {
        skip: !groupId || groupId === currentOrg?.id || isOrgInvalidated,
    });
    const { data: profileData }: any = useGetUserProfileQuery({}, { skip: !currentOrg?.id });

    const {
        data: dashboards,
        isLoading,
        isFetching,
    }: any = useGetActiveDashboardsQuery(
        {
            params: {
                resourceType: 'GROUP',
                resourceTypeId: groupId ?? currentOrg?.id,
            },
        },
        {
            skip: !currentOrg?.id,
            refetchOnMountOrArgChange: true,
        }
    );
    const { data: mqttEncryptedData }: any = useGetMQTTCredentialsQuery();

    React.useEffect(() => {
        if (mqttEncryptedData?.data && profileData) {
            const decryptedCredentials = decryptAES(mqttEncryptedData?.data, {
                userId: profileData?.data?.id,
                requestId: mqttEncryptedData?.requestId,
            });

            if (decryptedCredentials?.hostname !== window.APP_CONFIG.API_URL?.replace('https://', '')) {
                decryptedCredentials['hostname'] = window.APP_CONFIG.API_URL?.replace('https://', '');
            }
            dispatch(setMqttCredentials({ ...decryptedCredentials }));
        }
    }, [mqttEncryptedData, profileData]);

    useEffect(() => {
        dispatch(setSelectedNode(groupData?.data ?? ''));
        return () => dispatch(setAppHeaderTitle(undefined));
    }, [groupData]);

    const mqttConnectionStatus = useTypedSelector((state) => state['common']['mqttConnectionStatus']);

    const mqttCredentials = useTypedSelector((state) => state['common']['mqttCredentials']);

    const {
        currentData,
        isLoading: tabsLoader,
        isFetching: tabsFetcher,
    } = useGetAllTabsQuery(
        { resourceType: 'GROUP', resourceTypeId: groupId },
        { skip: !groupId, refetchOnMountOrArgChange: true }
    );

    const [tabsData, setTabsData] = React.useState<any>([]);

    React.useEffect(() => {
        if (currentData?.data?.tabs?.length) {
            setTabsData(MappedTabs({})?.filter((item: any) => currentData?.data?.tabs?.includes(item.id)));
        }
    }, [currentData]);

    React.useEffect(() => {
        if (tabsData?.length && (!selectedTab || selectedTab === '')) {
            if (location?.hash?.length) {
                setSelectedTab(location?.hash?.split('#')[1]);
            } else setSelectedTab(tabsData[0]?.id);
        }
    }, [tabsData]);

    React.useEffect(() => {
        if (
            mqttCredentials &&
            Object.keys(mqttCredentials)?.length &&
            (mqttConnectionStatus === false || mqttConnectionStatus === 'closed-device')
        ) {
            dispatch(setMqttConnectionStatus('loading'));
            connectMQTT(
                () => {
                    dispatch(setMqttConnectionStatus(true));
                },
                {
                    ...mqttCredentials,
                    protocol: 'wss',
                }
            );
        }
    }, [mqttCredentials, mqttConnectionStatus]);

    React.useEffect(
        () => () => {
            closeMqttConnection();
            dispatch(setMqttConnectionStatus('closed-group'));
        },
        []
    );

    const userData = (): any => {
        const menuGroups = [];
        if (canUpdateSystemDashboard || canUpdateUserDashboard) {
            menuGroups.push(
                {
                    icon: <DriveFileRenameOutline />,
                    title: <CustomTransComponent translationKey={'COMMON:EDIT_DETAILS'} />,
                    onClick: (): void => editRef?.current?.handleModalAction?.(true, dashboards?.data),
                },
                {
                    title: <CustomTransComponent translationKey={'DASHBOARD_MANAGEMENT:SELECT_DASHBOARD'} />,
                    icon: <Dashboard />,
                    onClick: (): void => {
                        selectModalRef?.current?.handleModalAction(true, { groupId });
                    },
                },
                {
                    title: <CustomTransComponent translationKey={'DASHBOARD_MANAGEMENT:EDIT_DASHBOARD'} />,
                    icon: <Edit />,
                    onClick: (): void => {
                        navigate(
                            `/${currentRealmName}/editDashboard/${groupId ?? currentOrg?.id}/${activeDashboardId}`,
                            {
                                state: {
                                    assignType: 'GROUP',
                                    assignId: groupId ?? currentOrg?.id,
                                },
                            }
                        );
                    },
                }
            );
        }
        return {
            id: 'profile-menu',
            menuTitle: '',
            menuSubtitle: '',
            menuGroups,
        };
    };

    const getActions = (): JSX.Element => {
        if (selectedTab === 'summary' && activeDashboardId) {
            if (canUpdateSystemDashboard || canUpdateUserDashboard) {
                return <CustomMenu menuList={userData()} />;
            }
        }
        if (selectedTab === 'devices') {
            return <DeviceMenu />;
        }
        return <></>;
    };

    const [selectedTab, setSelectedTab] = React.useState<any>();

    const onTabChange = (activeTab: string): void => {
        setSelectedTab(tabsData?.find((tab: any) => tab.id === activeTab)?.id ?? '');
    };

    return (
        <Box className="w-100">
            <CustomTabs
                tabsData={tabsFetcher || tabsLoader ? CustomTabsSkeleton() : tabsData}
                variableHeader
                actions={getActions()}
                key="aed24fqe4q"
                showExtraTabsInMenu
                initialTab={tabsData?.[0]?.id}
                onTabChange={onTabChange}
            />
            {isLoading && isFetching && <Loader size={60} />}
            {(canUpdateSystemDashboard || canUpdateUserDashboard) && <SelectDashboardModal ref={selectModalRef} />}

            <EditDashboardDetails ref={editRef} />
        </Box>
    );
};
