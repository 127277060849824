import React from 'react';
import { ChevronRight, Schedule } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { Device } from '@brightlayer-ui/icons-mui';
import clsx from 'clsx';
import moment from 'moment';
import { transformScheduleWidgetData } from '@fiji/common/src/utils/helpers';
import { ScheduleDetails } from 'pages/Scheduling/ScheduleDetails';

type ScheduleProps = {
    data: any;
    maxCount: number;
    mode: string;
};

const ScheduleData = ({ data, maxCount, mode }: ScheduleProps): JSX.Element => {
    const detailRef = React.useRef<any>(null);
    const formatDate = (timestamp: number): string => moment(timestamp).format('MMMM, D');

    const formatTime = (timestamp: number): string => moment(timestamp).format('hh:mm A');

    const getRelativeDay = (timestamp: number): string => {
        const today = moment();
        const date = moment(timestamp);

        if (date.isSame(today, 'day')) return 'Today';
        if (date.isSame(today.add(1, 'days'), 'day')) return 'Tomorrow';
        if (date.isSame(today.subtract(2, 'days'), 'day')) return 'Yesterday';
        return '';
    };

    const trimSchedule = (schedulesData: any, limit: number): any[] => {
        const allSchedule = schedulesData.flatMap((schedule: any) => schedule?.schedules).slice(0, limit);
        const remainingSchedules = [...allSchedule];

        return schedulesData.map((schedule: any) => ({
            ...schedule,
            schedules: schedule.schedules?.slice(0, remainingSchedules.length),
        }));
    };

    const trimmedData = trimSchedule(transformScheduleWidgetData(data), maxCount);

    const handleEventClick = (eventId: string): void => {
        detailRef?.current?.handleScheduleDetails({ id: eventId });
    };

    const getLabel = (start: any, end: any): string => {
        if (start && end) {
            return `${formatTime(start)} - ${formatTime(end)}`;
        } else if (start) {
            return formatTime(start);
        }
        return '--';
    };

    const renderEventStatus = (event: any): string => {
        const currentTimestamp = new Date().getTime();
        return clsx({
            'ongoing-event-component': event?.startTime <= currentTimestamp && event?.endTime >= currentTimestamp,
            'future-event-component': currentTimestamp < event?.startTime,
            'past-event-component': currentTimestamp > event?.endTime,
        });
    };

    return (
        <>
            <Stack>
                {trimmedData?.map(
                    (schedule: any) =>
                        schedule?.schedules?.length > 0 && (
                            <Stack key={schedule?.id} pt={2} px={2} className="border-bottom-1">
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Schedule className="height-16 width-16" />
                                    {getRelativeDay(schedule?.scheduleDate) && (
                                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                            {getRelativeDay(schedule?.scheduleDate)}
                                        </Typography>
                                    )}
                                    <Box className="height-6 width-6 border-radius-50 bg-content-color" />
                                    <Typography variant="body2" fontSize={'14px'}>
                                        {formatDate(schedule?.scheduleDate)}
                                    </Typography>
                                </Stack>
                                {schedule?.schedules?.map?.((event: any, eventIndex: number) => (
                                    <Stack
                                        key={`${schedule?.id}-${eventIndex}`}
                                        p={2}
                                        direction="row"
                                        justifyContent="space-between"
                                        className="border-bottom-1"
                                    >
                                        <Stack
                                            direction={'row'}
                                            alignItems={'center'}
                                            spacing={3}
                                            className="flex-60"
                                            justifyContent={'space-between'}
                                        >
                                            <ListItemTag
                                                label={getLabel(event?.startTime, event?.endTime)}
                                                fontSize="10px"
                                                fontWeight={700}
                                                letterSpacing={1}
                                                textTransform="uppercase"
                                                className={renderEventStatus(event)}
                                            />
                                            <Typography variant="subtitle1">{event?.name}</Typography>
                                        </Stack>
                                        <Stack
                                            direction={'row'}
                                            spacing={3}
                                            justifyContent={'space-between'}
                                            className="flex-30"
                                        >
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Device className="color-content" />
                                                <Typography variant="body2" fontSize="15px" fontWeight={600}>
                                                    {event?.deviceCount}
                                                </Typography>
                                            </Stack>
                                            <IconButton
                                                disabled={mode !== 'view'}
                                                onClick={() => handleEventClick(schedule?.id)}
                                            >
                                                <ChevronRight />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                ))}
                            </Stack>
                        )
                )}
            </Stack>
            <ScheduleDetails ref={detailRef} />
        </>
    );
};

export default ScheduleData;
