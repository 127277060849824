import { isAncestor } from './isAncestor';
import { NodeModel, TreeState } from '../types';
import { isNodeModel } from './isNodeModel';

export const isDroppable = <T>(dragSource: any, dropTargetId: NodeModel['id'], treeContext: TreeState<T>): boolean => {
    const { tree, parentNode, canDrop } = treeContext;

    // Dropability judgment of each node in the undragged state.
    // Without this process, the newly mounted node will not be able to be dropped unless it is re-rendered
    if (dropTargetId === parentNode?.id && dragSource === null) {
        return true;
    }
    if (dragSource === null) {
        const dropTargetNode = tree.find((node) => node.id === dropTargetId);

        if (dropTargetNode?.droppable) {
            return true;
        }

        return false;
    }
    const dragSourceId = isNodeModel<T>(dragSource) ? dragSource?.id : null;

    const result = canDrop?.(dragSourceId, dropTargetId);

    if (result !== undefined) {
        return result;
    }

    if (dragSourceId === dropTargetId) {
        return false;
    }

    const dragSourceNode = tree.find((node) => node.id === dragSourceId);
    const dropTargetNode = tree.find((node) => node.id === dropTargetId);

    // dragSource is external node
    if (dragSourceNode === undefined || dragSourceId === null) {
        return dropTargetId === parentNode?.id || !!dropTargetNode?.droppable;
    }

    // dropTarget is root node
    if (dropTargetNode === undefined) {
        return dragSourceNode.parent !== 0;
    }

    if (dragSourceNode.parent === dropTargetId || !dropTargetNode.droppable) {
        return false;
    }

    return !isAncestor(tree, dragSourceId, dropTargetId);
};
