import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { WidgetHeader, WidgetFooter, CHART_INITIAL_CONFIG } from 'pages/WidgetManagement/common';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Divider, MenuItem, Paper, Radio, Stack } from '@mui/material';
import { StyledMenu } from 'pages/Chart/StyledMenu';
import { CustomTransComponent } from 'components';
import { getChartMaxValue, getIntervalValue, getStringGroupPath } from 'pages/WidgetManagement/common/utils/helpers';
import { useTransformData } from 'hooks/useTransformData';
import { useGetChannelUnitsQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { seriesColors } from '@fiji/common';

type TelemetryChartProps = {
    type?: 'bar' | 'column';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount?: number;
    secondary: any[];
    widgetName?: string;
    [key: string]: any;
};

const getDataLabels = (pointConversion: any, chartRect: any): any => ({
    enabled: true,
    // align: 'left',
    useHTML: true,
    formatter: function (this: any): string {
        const width = `${(Math.round(chartRect ?? 300) / 10) * 9 - 10}px`;
        return `<span 
                            style="
                            display: block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            visibility: visible;
                            font-weight: 500;
                            word-spacing: 2px;
                            width: ${width}"
                        >
                            <span style="font-weight: 700;">${pointConversion(this?.y)}</span>${
            this?.series?.tooltipOptions?.valueSuffix
        } ${this?.series?.name}
                        </span>`;
    },
    style: {
        fontSize: '14px',
    },
});

const Component = ({
    widgetData: chartWidget,
    mode,
}: {
    widgetData: TelemetryChartProps;
    mode: string;
}): JSX.Element => {
    const { pointConversion } = useTransformData();
    const chartRef: any = useRef(null);
    const [option, setOption] = React.useState(CHART_INITIAL_CONFIG);
    const componentRef = React.useRef<HTMLDivElement | null>(null);
    const [chartRect, setChartRect] = React.useState<any>(null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedUnits, setSelectedUnits] = React.useState<any>(chartWidget?.selectedUnit ?? '');
    const open = Boolean(anchorEl);

    const { data: unitList } = useGetChannelUnitsQuery(getPayload(), {
        skip: !getPayload()?.length || mode !== 'view',
    });
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    useEffect(() => {
        Highcharts.setOptions({
            yAxis: {
                lineColor: chartWidget?.type === 'bar' ? '#f0ebeb' : '#ffffff',
            },
        });
    }, [chartWidget?.type]);

    const getSeriesData = (data: any): any[] =>
        data?.map((item: any, index: number) => ({
            name: chartWidget?.multiDevice
                ? `${item?.displayName}/${getStringGroupPath(item?.devicePath, item?.deviceName)}`
                : item?.displayName ?? '',
            data: [item?.value ? item?.value : 0],
            color: seriesColors[index],
            tooltip: {
                valueSuffix: item?.unit ? item?.unit : '',
            },
        }));

    const handleResizeComponent = (): void => {
        const hightWidth = componentRef.current
            ?.getElementsByClassName('highcharts-background')?.[0]
            ?.getBoundingClientRect();
        setChartRect(chartWidget?.type === 'bar' ? hightWidth?.width : hightWidth?.height);
    };

    React.useEffect(() => {
        handleResizeComponent();
        window?.addEventListener('resize', handleResizeComponent);
        componentRef?.current?.addEventListener('resize', handleResizeComponent);
    }, []);

    useEffect(() => {
        if (unitList?.data?.length) {
            setSelectedUnits(unitList?.data[0]);
        }
    }, [unitList]);

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        let data = JSON?.parse(JSON?.stringify(chartWidget?.secondary ?? []));
        if (selectedUnits) {
            data = data?.filter((item: any) => item?.unit === selectedUnits);
        }
        data = data?.slice(0, chartWidget?.maxCount ?? 10);
        if (chart) {
            const cloneChart: any = JSON.parse(JSON.stringify(option));
            cloneChart['series'] = getSeriesData(data) ?? [];
            cloneChart['chart'] = {
                type: chartWidget?.type,
                scrollablePlotArea: {
                    minHeight: 700,
                    opacity: 1,
                },
                height: '65%',
            };

            cloneChart['plotOptions'] = {
                bar: {
                    dataLabels: {
                        enabled: true,
                        y: 25,
                        alignTo: 'plotEdges',
                        align: 'left',
                    },
                    borderRadius: '20%',
                    pointWidth: 25,
                },
                column: {
                    dataLabels: {
                        x: 25,
                        align: 'right',
                        alignTo: 'plotEdges',
                        enabled: true,
                        rotation: 270,
                    },
                    borderRadius: '20%',
                    pointWidth: 25,
                },
                series: {
                    groupPadding: 0,
                    pointPadding: 0.3,
                    dataLabels: getDataLabels(pointConversion, chartRect),
                },
            };
            cloneChart['yAxis'] = {
                ...(option?.yAxis ?? {}),
                labels: {
                    format: `{value} ${getChartMaxValue(data)?.unit ?? ''}`,
                    overflow: 'justify',
                },
                tickInterval: getIntervalValue(data),
                lineColor: chartWidget?.type === 'bar' ? '#424e541f' : '#424e5400',
            };
            setOption(cloneChart);
        }
    }, [chartWidget, selectedUnits, chartRect]);

    function getPayload(): any {
        const payload: any = [];
        chartWidget?.secondary?.forEach((item: any) => {
            const selectedIndex = payload?.findIndex((subItem: any) => subItem.deviceId === item.deviceId);
            const selectedItem = payload?.find((subItem: any) => subItem.deviceId === item.deviceId);
            if (selectedItem) {
                payload?.[selectedIndex]?.channelId.push(item.channelId);
            } else {
                payload?.push({ deviceId: item.deviceId, channelId: [item.channelId] });
            }
        });
        return payload;
    }

    return (
        <Paper sx={{ height: '100%' }} ref={componentRef}>
            <WidgetHeader mode={mode} widgetData={chartWidget} />
            {mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - 97px)`,
                    overflow: 'auto',
                }}
            >
                <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems={'flex-end'}
                    p={2}
                    pr={5}
                >
                    <Box mr={2}>
                        <Button
                            disabled={mode === 'preview' || mode === 'edit'}
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            sx={{
                                backgroundColor: 'transparent',
                                color: '#424E54',
                            }}
                            onClick={handleClick}
                            endIcon={!open ? <ArrowDropDown /> : <ArrowDropUp />}
                        >
                            <CustomTransComponent translationKey={'COMMON:UNITS'} />
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {unitList?.data?.map((item: any): any => (
                                <MenuItem key={item} value={item} className="w-100">
                                    <Radio
                                        className="margin-right-16"
                                        checked={selectedUnits === item}
                                        onChange={(): void => setSelectedUnits(item)}
                                    />
                                    {item}
                                </MenuItem>
                            ))}
                        </StyledMenu>
                    </Box>
                </Stack>
                <HighchartsReact highcharts={Highcharts} options={option} ref={chartRef} />
            </Stack>

            <WidgetFooter
                mode={mode}
                widgetData={{ ...chartWidget, viewAllButton: true }}
                ids={[]}
                disabled={!chartWidget?.secondary?.length}
                actionBtnName={<CustomTransComponent translationKey={'COMMON:VIEW_FULL_CHART'} />}
            />
        </Paper>
    );
};
export default Component;
