import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Container,
    Grid,
    TextField,
    Typography,
    Stack,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useMediaQuery,
    CardContent,
    CardActions,
    Box,
    Divider,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { useForm } from 'react-hook-form';

//components
import { Hierarchy } from '../../components/Hierarchy';
import { AutoCompleteTags, DiscardModal } from './index';
import { BackdropLoader } from '../../components/BackdropLoader';
import { CustomHeader, CustomTransComponent, renderGroupHierarchyIcons } from '../../components';
import Element from '../../components/Element';

import { handleWhiteSpaces, toFirstLetterUpperCase } from '../../utils/helpers';
import { useNavigationBack } from 'hooks';
import {
    DEVICE_FIELDS_LIMIT,
    TAGS_LIMIT,
    BLUEPRINT_ORGANIZATION_NAME,
    VENDOR_NAME,
    DEVICE_CATEGORY,
    MasterDataKey,
} from '@fiji/common/src/constants';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import {
    useCreateTagMutation,
    useGetTagsListMutation,
    useGetTagsQuery,
} from '@fiji/common/src/features/TagManagement/tagManagementApi';
import {
    useGetMasterDataMutation,
    useGetUIMetadataMutation,
    useGetDeviceByIdQuery,
    useUpdateDeviceConfigMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import { setMessageContent, setAppHeaderSubTitle } from '@fiji/common/src/features/common/commonSlice';
import { Device } from '@fiji/common/src/types/Device';

//styles
import useStyles from './styles';
import { useParams } from 'react-router-dom';
import { TagType } from '@fiji/common/src/types';
import { CustomModal, ModalActions } from 'components/CustomModal';
//Constraint
import { metadata } from '@fiji/common/src/constants/validation';
import { useTranslation } from 'react-i18next';

const initialDevicePayload = {
    description: '',
    deviceName: '',
    deviceType: '',
    tagIds: [],
    deviceModel: '',
    wrapperDeviceType: '',
    vendorTypeName: '',
};

type DeviceType = {
    key: string;
    name: string;
    type: string;
    vendor: string;
    profileId: string;
};

type DeviceModel = {
    key: string;
    value: string;
    profileId?: string;
    serial: string;
    vendor: string;
};

export const EditDevice = (): JSX.Element => {
    const { deviceId } = useParams();
    const { t } = useTranslation();
    const theme: any = useTheme();
    const dispatch = useAppDispatch();
    const classes = useStyles(theme);
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const currentOrg = useTypedSelector(selectedOrg);
    const navigationBack = useNavigationBack();
    const [devicePayload, setDevicePayload] = useState<Partial<Device>>({
        ...initialDevicePayload,
    });
    const [discardChangesModal, setDiscardChangesModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [openSelectBox, setOpenSelectBox] = useState(false);
    const [deviceModels, setDeviceModels] = React.useState([]);
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState<{ [key: string]: any }>({});
    const [groupDetails, setGroupDetails] = React.useState<any>({
        destinationGroup: '',
    });
    const [connectedGatewayGroup, setConnectedGatewayGroup] = React.useState<any>({
        id: '',
    });
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [deviceType, setDeviceType] = useState<DeviceType | null>(null);
    const [elements, setElements] = React.useState<any[]>([]);
    const [DeviceNameValidation] = useState<any>(metadata.validation.addDeviceForm.DeviceName);
    const [TagNameValidation] = useState<any>(metadata.validation.addDeviceForm.Tags);
    const [isTagError, setIsTagError] = useState(false);

    const [getHeirarchyData, { isLoading: hierarchyDataLoader }] = useGetAllGroupsMutation({
        fixedCacheKey: 'cachedAllGroupData',
    });

    const {
        data: deviceTagsList,
        isLoading: isDeviceTagLoading,
        isFetching: isDeviceTagFetching,
        refetch: refetchDeviceTags,
    } = useGetTagsQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>({
        page: 0,
        size: 100,
    });

    const [createTag, { isLoading: isTagCreating }] = useCreateTagMutation();
    const [updateDevice, { isLoading: isDeviceUpdated }]: any = useUpdateDeviceConfigMutation();
    const [getTagsList, { isLoading: isTagsListLoading }]: any = useGetTagsListMutation();

    const {
        handleSubmit,
        control,
        formState: { isValid },
        watch,
        setValue,
        reset,
    } = useForm({ mode: 'onChange' });

    const [getMasterData, { isLoading: isLoadingMasterData }]: any = useGetMasterDataMutation();
    const [getUIMetadata, { isLoading: isLoadingUiMetadata }]: any = useGetUIMetadataMutation();
    const { data: deviceData, isLoading: isDeviceDataLoading } = useGetDeviceByIdQuery(deviceId, {
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        const getDeviceTypes = async (): Promise<void> => {
            const deviceTypesData = await fetchMasterData(MasterDataKey.DEVICETYPES);
            setDeviceTypes(deviceTypesData?.data?.data?.data?.value);
        };

        void getDeviceTypes();
    }, []);

    useEffect(() => {
        setDevicePayload((prev: any) => ({
            ...prev,
            ...deviceData?.data,
            deviceName: deviceData?.data?.name,
            deviceType: deviceData?.data?.deviceTypeCategory,
            deviceModel: deviceData?.data?.deviceModel,
            tagIds: deviceData?.data?.tags.map((tag: any) => tag?.name) ?? [],
        }));
        setGroupDetails((prev: any) => ({
            ...prev,
            destinationGroup: deviceData?.data?.groupId,
            destinationGroupName: deviceData?.data?.groupName,
            destinationGroupIcon: { web: { family: 'material-ui', name: 'CorporateFare' } },
        }));
        setConnectedGatewayGroup((prev: any) => ({
            ...prev,
            id: deviceData?.data?.parentDeviceId ? deviceData?.data?.parentDeviceId : deviceData?.data?.parentId,
            name: deviceData?.data?.parentDeviceName,
        }));
        const selectedDeviceType: DeviceType | undefined = deviceTypes.find(
            (element: any) => element.key === deviceData?.data?.deviceTypeCategory
        );
        setDeviceType(selectedDeviceType ?? null);
    }, [deviceData, deviceTypes]);

    /* The below code is using the `useEffect` hook in a TypeScript React component. It is setting up a
    side effect that will be triggered whenever the `currentOrg` variable changes. */
    useEffect(() => {
        if (currentOrg?.id) {
            refetchDeviceTags()
                .then(() => {})
                .catch(() => {});
        }
    }, [currentOrg]);

    useEffect(() => {
        if (devicePayload?.deviceType) {
            const getModelsData = async (): Promise<void> => {
                const modelsData = await fetchMasterData(deviceType?.key ?? '');

                setDeviceModels(modelsData.data?.data?.data?.value);
            };
            void getModelsData();
        }
    }, [deviceType]);

    useEffect(() => {
        if (devicePayload?.deviceType) {
            const selectedDeviceModel: DeviceModel | undefined = deviceModels.find(
                (x: any) => x.key.toLowerCase() === devicePayload.deviceModel.toLowerCase()
            );

            if (selectedDeviceModel && devicePayload.deviceModel && deviceType?.type !== DEVICE_CATEGORY.child) {
                void fetchUIMetadata(devicePayload.vendorTypeName ?? '');
            }
        }
    }, [devicePayload.deviceModel, deviceModels]);
    useEffect(() => {
        if (devicePayload.deviceModel && deviceType?.type === DEVICE_CATEGORY.child) {
            void fetchUIMetadata(devicePayload.vendorTypeName);
        }
    }, [connectedGatewayGroup]);
    React.useEffect(() => {
        dispatch(setAppHeaderSubTitle(deviceData?.data?.name));
        return (): any => {
            dispatch(setAppHeaderSubTitle(''));
        };
    }, [deviceData]);
    useEffect(() => {
        if (elements.length) {
            elements.forEach((element: any) => {
                if (element.id === 'deviceId') {
                    setValue('deviceId', deviceData?.data.id, { shouldValidate: true });
                } else {
                    setValue(element.id, deviceData?.data[element.id], { shouldValidate: true });
                }
            });
        }
    }, [elements]);

    const fetchUIMetadata = async (vendor: string): Promise<void> => {
        const uiMetadataDataRes = await getUIMetadata({
            vendorTypeName: vendor,
            deviceTypeName: toFirstLetterUpperCase(deviceType?.type ?? ''),
        });
        const uiMetadataData = uiMetadataDataRes?.data?.data?.data;

        const uiMetadataDataUpdate = Array.isArray(uiMetadataData)
            ? uiMetadataData.map((element: any) => ({
                  ...element,
                  disabled: !(element.editable || element.editable === undefined),
              }))
            : [];
        setElements(uiMetadataDataUpdate);
    };

    const fetchMasterData = async (key: string): Promise<any> => {
        const deviceModelsData = await getMasterData({
            vendorName: BLUEPRINT_ORGANIZATION_NAME,
            customerName: VENDOR_NAME,
            key,
        });

        return deviceModelsData;
    };

    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getHeirarchyData({ parent: parentData?.id });

        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
    };

    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    /**
     * The `payloadHandler` function updates the `devicePayload` state based on the value of the target
     * element, and also triggers a debounce handler for specific target IDs.
     * @param {any} e - The parameter `e` is an event object that is passed to the `payloadHandler`
     * function. It contains information about the event that triggered the function, such as the
     * target element, the event type, and any additional data associated with the event.
     * @returns The function `payloadHandler` returns `void`, which means it does not return any value.
     */
    const payloadHandler = (e: any): void => {
        let key: string = e.target.id;

        if (e.target.value && key === 'deviceName') {
            // show error right away if it is regexp error
            const regexp = new RegExp(DeviceNameValidation.pattern);
            const testReturn = regexp.test(e.target.value);
            if (!testReturn) {
                return;
            }
        }

        if (e.target.name) {
            key = e.target.name;
        }
        if (e.target.value.length > DEVICE_FIELDS_LIMIT[key]) {
            return;
        }
        setDevicePayload((prev) => ({ ...prev, [key]: e.target.value }));
    };

    /**
     * The function `selectedTagsHandler` updates the `tagIds` property in the `devicePayload` state
     * based on the selected tags.
     * @param {any} e - The parameter "e" is an event object that represents the event that triggered
     * the selectedTagsHandler function. It is typically used to access information about the event,
     * such as the target element or the event type.
     * @param {any} values - The `values` parameter is an array that contains the selected tags.
     * @returns If the length of `values` is greater than `TAGS_LIMIT`, nothing is being returned.
     * Otherwise, the `setDevicePayload` function is being called with an updated `tagIds` property in
     * the `prev` object.
     */
    const selectedTagsHandler = (e: any, values: any): void => {
        if (values?.length > TAGS_LIMIT) {
            return;
        }
        setDevicePayload((prev: any) => ({ ...prev, tagIds: values }));
    };

    /**
     * The function `handleKeyDown` is an asynchronous function that is triggered when a key is
     * pressed, and if the pressed key is the Enter key and certain conditions are met, it creates a
     * new device tag and updates the device payload.
     * @param {any} e - The parameter `e` is an event object that represents the keydown event. It
     * contains information about the event, such as the key code and target element.
     */
    const handleKeyDown = (e: any): void => {
        setIsTagError(false);
        // show error right away if it is regexp error
        const regexp = new RegExp(TagNameValidation.pattern);
        const testReturn = regexp.test(e.target.value);

        if (!testReturn) {
            setIsTagError(true);
        }

        if (e.code === 'Enter' && devicePayload?.tagIds && devicePayload?.tagIds?.length < TAGS_LIMIT && testReturn) {
            const tagValue = e.target.value;

            const devicePayloadClone = JSON.parse(JSON.stringify(devicePayload));
            devicePayloadClone.tagIds.push(tagValue);
            setDevicePayload(devicePayloadClone);
        }
    };

    const createDeviceTag = async (): Promise<void> => {
        let filteredTagsChosenIds = [];
        const allTagsDataNames = deviceTagsList?.data.records.map((item: TagType) => item.name);
        const newTagsToCreate = devicePayload.tagIds?.filter((x) => !allTagsDataNames.includes(x));

        if (newTagsToCreate?.length) {
            const createTagPromises = newTagsToCreate.map(
                async (tagName) =>
                    await createTag({
                        name: tagName,
                    })
            );
            await Promise.all(createTagPromises);

            const result = await getTagsList({
                page: 0,
                size: 100,
            });
            const resultTags = result?.data?.data?.records;
            filteredTagsChosenIds = resultTags
                ?.filter((x: { name: any }) => devicePayload.tagIds?.includes(x.name))
                .map((item: { id: any }) => item.id);
        } else {
            filteredTagsChosenIds = deviceTagsList?.data.records
                ?.filter((x: { name: any }) => devicePayload.tagIds?.includes(x.name))
                .map((item: { id: any }) => item.id);
        }
        return filteredTagsChosenIds;
    };

    /**
     * The submitHandler function is used to handle form submission in a TypeScript React component,
     * updating or creating a device based on the form data.
     * @param {any} data - The parameter `data` is an data coming for dynamic form fields.
     * @param {any} e - The parameter `e` is an event object that is passed to the `submitHandler`
     * function. It is typically an object that contains information about the event that triggered the
     * function, such as the target element that was clicked or the form that was submitted. In this
     * case, it is being used to
     */
    const submitHandler = async (data: any): Promise<void> => {
        const deviceTags = await createDeviceTag();
        const body = {
            requestedFrom: 'Remote', // for starfish
            name: devicePayload.deviceName,
            groupId: groupDetails.destinationGroup,
            description: devicePayload.description,
            tags: deviceTags,
            ...(deviceType?.type === DEVICE_CATEGORY.child && {
                parentDeviceId: devicePayload.parentDeviceId ? devicePayload.parentDeviceId : devicePayload.parentId,
            }),
            ...data,
        };

        const payload = {
            deviceId: deviceId,
            body: {
                payload: body,
            },
        };

        const { error }: any = await updateDevice(payload);

        if (!error) {
            resetForm();
            setConfirmationModal(false);
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('DEVICE_MANAGEMENT:EDIT_SUCCESS_MSG'),
                    duration: 3000,
                })
            );

            navigationBack();
        }
    };

    /**
     * The function `handleTagDelete` is used to remove a tag from an array of tag IDs in a device
     * payload.
     * @param {any} data - The `data` parameter is of type `any`, which means it can be any data type.
     * It is not specified what kind of data it represents in this context.
     * @param {number} index - The `index` parameter is the index of the element in the `tagIds` array
     * that needs to be deleted.
     */
    const handleTagDelete = (data: any, index: number): void => {
        const devicePayloadClone = JSON.parse(JSON.stringify(devicePayload));
        devicePayloadClone.tagIds.splice(index, 1);
        setDevicePayload(devicePayloadClone);
    };

    const isValidDeviceModel = (): boolean => {
        if (devicePayload.deviceModel === '') {
            return true;
        }
        return false;
    };

    const isValidConnectedGateway = (): boolean => {
        if (deviceType?.type === DEVICE_CATEGORY.child && connectedGatewayGroup.id === '') {
            return true;
        }

        return false;
    };

    /**
     * The function `isValidCommonField` returns `true` if any of the specified conditions are met,
     * otherwise it returns `false`.
     * @returns The function `isValidCommonField` returns a boolean value.
     */
    const isValidCommonField = (): boolean => {
        if (
            devicePayload.deviceName === '' ||
            devicePayload.deviceType === '' ||
            groupDetails.destinationGroup === '' ||
            !handleWhiteSpaces([devicePayload.deviceName, devicePayload?.description])
        ) {
            return true;
        }
        return false;
    };

    const disableHandler = (): boolean =>
        isFieldChanged() || isValidCommonField() || isValidDeviceModel() || isValidConnectedGateway() || !isValid;

    /**
     * The function `handleToggleSelectBox` toggles the state of `openSelectBox` between `true` and
     * `false`.
     */
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => {
            if (!prev === false) {
                setHierarchyMappingData({});
            }
            return !prev;
        });
    };

    /**
     * The function checks if the initial device payload is equal to the current device payload and
     * navigates to a different page if they are, otherwise it sets a discard changes modal to true.
     */
    const handleDiscardChanges = (): void => {
        if (isFieldChanged()) {
            navigationBack();
        } else {
            setDiscardChangesModal(true);
        }
    };

    const isFieldChanged = (): boolean => {
        const tagsFilter = deviceData?.data?.tags.map((tag: any) => tag.name);
        const changedArr: any = [];
        elements.forEach((element) => {
            if (element.type === 'label' || element.id === 'deviceId') return;
            // eslint-disable-next-line eqeqeq
            if (deviceData?.data[element.id] == watch(element.id)) {
                changedArr.push({ id: element.id, changed: false });
            } else {
                changedArr.push({ id: element.id, changed: true });
            }
        });

        const filteredArr = changedArr.filter((element: any) => element.changed === true);

        if (
            groupDetails.destinationGroup === deviceData?.data?.groupId &&
            devicePayload.deviceName === deviceData?.data?.name &&
            devicePayload.description === deviceData?.data?.description &&
            JSON.stringify(tagsFilter) === JSON.stringify(devicePayload.tagIds) &&
            filteredArr.length === 0
        ) {
            return true;
        }
        return false;
    };

    const actions: ModalActions[] = [
        {
            key: 'goBack',
            testId: 'goBack',
            label: <CustomTransComponent translationKey={'COMMON:GO_BACK'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                setConfirmationModal(false);
            },
        },
        {
            key: 'closeWithoutSaving',
            testId: 'closeWithoutSaving',
            label: <CustomTransComponent translationKey={'DEVICE_MANAGEMENT:CLOSE_WITHOUT_SAVING_BTN'} />,
            variant: 'outlined',
            handleClick: (): void => {
                setConfirmationModal(false);
                navigationBack();
            },
        },
        {
            key: 'continue',
            testId: 'continue',
            label: <CustomTransComponent translationKey={'COMMON:CONTINUE'} />,
            variant: 'contained',
            handleClick: (e: any): void => {
                handleSubmit(submitHandler)(e) as any;
            },
        },
    ];

    const resetForm = (): void => {
        setDeviceType(null);
        reset();
        setElements([]);
        setDevicePayload({
            ...initialDevicePayload,
        });
    };

    return (
        <React.Fragment>
            <header>
                <title>{t('EDIT_DEVICE_FORM.EDIT_DEVICE_CONFIGURATION')}</title>
                <meta name="description" content="{t('EDIT_DEVICE_FORM.EDIT_DEVICE_CONFIGURATION')}" />
            </header>

            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
                minHeight={'100vh'}
                position={'relative'}
            >
                <CustomHeader
                    title={<CustomTransComponent translationKey={'ROUTE_TITLES:EDIT_DEVICE_CONFIG'} />}
                    subtitle={deviceData?.data?.name}
                    handleNavigateBack={handleDiscardChanges}
                />

                <Container fixed className={classes.ContainerWrapper}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardBody}>
                            <Typography variant={'h6'} className={classes.sectionHeader}>
                                <CustomTransComponent
                                    translationKey={'EDIT_DEVICE_FORM:WEB_HEADER'}
                                    replace={{ name: deviceData?.data?.name }}
                                />
                            </Typography>
                            <Typography variant={'body2'} className={classes.sectionHeader}>
                                {<CustomTransComponent translationKey={'EDIT_DEVICE_FORM:INFORM_MANDATORY_FIELDS'} />}
                            </Typography>
                            <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
                                <InputLabel id={'select-device-type'} required={true}>
                                    <CustomTransComponent translationKey={'DEVICE_FORM_COMMON:DEVICE_TYPE'} />
                                </InputLabel>
                                <Select
                                    disabled={true}
                                    labelId={'select-device-type'}
                                    value={devicePayload?.deviceType ?? ''}
                                    name="deviceType"
                                >
                                    {deviceTypes?.map((device: any) => (
                                        <MenuItem key={`key${device.id}`} value={device?.key}>
                                            {device?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
                                <InputLabel id={'select-device-model'} required={true}>
                                    <CustomTransComponent translationKey={'DEVICE_FORM_COMMON:DEVICE_MODEL'} />
                                </InputLabel>
                                <Select
                                    disabled={true}
                                    labelId={'select-device-model'}
                                    value={devicePayload?.deviceModel ?? ''}
                                    name="deviceModel"
                                >
                                    {deviceModels?.map((device: any) => (
                                        <MenuItem key={`key${device.id}`} value={device?.key}>
                                            {device?.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {deviceType?.type !== DEVICE_CATEGORY.standalone && (
                                <>
                                    {devicePayload.deviceModel && deviceType?.type === DEVICE_CATEGORY.child && (
                                        <FormControl
                                            variant={'filled'}
                                            className={classes.formControl}
                                            required
                                            fullWidth
                                        >
                                            <InputLabel id={'select-connected-gateway'} required={false}>
                                                <CustomTransComponent
                                                    translationKey={'EDIT_DEVICE_FORM:CONNECTED_GATEWAY'}
                                                />
                                            </InputLabel>
                                            <Select
                                                displayEmpty
                                                id="connectedGateway"
                                                labelId="select-connected-gateway"
                                                disabled={true}
                                                renderValue={(): any => (
                                                    <Typography
                                                        sx={{
                                                            display: 'flex',
                                                            alignContent: 'center',
                                                            py: '8px',
                                                        }}
                                                    >
                                                        <div>
                                                            {connectedGatewayGroup?.icon &&
                                                                renderGroupHierarchyIcons({
                                                                    icon: connectedGatewayGroup?.icon,
                                                                })}
                                                        </div>
                                                        <span>{connectedGatewayGroup?.name}</span>
                                                    </Typography>
                                                )}
                                                value={connectedGatewayGroup?.name ? ' ' : ''}
                                            ></Select>
                                        </FormControl>
                                    )}
                                </>
                            )}

                            <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
                                <InputLabel id={'select-Assign-to-Group'} required={false}>
                                    <CustomTransComponent translationKey={'COMMON:ASSIGN_TO_GRP'} />
                                </InputLabel>
                                <Select
                                    displayEmpty
                                    id="groupId"
                                    labelId="demo-customized-select-label"
                                    disabled={hierarchyDataLoader}
                                    renderValue={(): any => (
                                        <Typography sx={{ display: 'flex', alignContent: 'center', py: '8px' }}>
                                            <div>
                                                {renderGroupHierarchyIcons({
                                                    icon: groupDetails?.destinationGroupIcon,
                                                })}
                                            </div>
                                            <span>{groupDetails?.destinationGroupName}</span>
                                        </Typography>
                                    )}
                                    value={groupDetails?.destinationGroupName ? ' ' : ''}
                                    open={openSelectBox}
                                    onClose={handleToggleSelectBox}
                                    onOpen={handleToggleSelectBox}
                                >
                                    <Hierarchy
                                        labelKey="name"
                                        data={[
                                            {
                                                id: currentOrg?.id,
                                                name: `${currentOrg?.name} (Organization Root)`,
                                                icon: {
                                                    web: { family: 'material-ui', name: 'CorporateFare' },
                                                },
                                                groupCount: currentOrg?.groupCount,
                                                deviceCount: currentOrg?.deviceCount,
                                            },
                                        ]}
                                        loadMore={handleFetchHierarchy}
                                        cleanHiearchyNode={handleCleanHierarchyNode}
                                        hierarchyMappingData={hierarchyMappingData}
                                        loadingNode={loadingHierarchyNode}
                                        selectionType={'radio'}
                                        selectedNodes={groupDetails?.destinationGroup}
                                        hasChild={(treeItem): boolean => treeItem?.groupCount}
                                        getAvatarChild={renderGroupHierarchyIcons}
                                        handleTreeNodeSelection={(parentNode): void => {
                                            setGroupDetails((prev: any) => ({
                                                ...prev,
                                                destinationGroup: parentNode.id,
                                                destinationGroupName: parentNode.name,
                                                destinationGroupIcon: parentNode.icon,
                                            }));
                                            handleToggleSelectBox();
                                        }}
                                        filters={[
                                            {
                                                key: 'type',
                                                operator: '!==',
                                                value: 'DEVICE',
                                                action: 'hidden',
                                            },
                                            {
                                                key: 'type',
                                                operator: '!==',
                                                value: 'GATEWAY',
                                                action: 'hidden',
                                            },
                                            {
                                                key: 'type',
                                                operator: '!==',
                                                value: 'CHILD',
                                                action: 'hidden',
                                            },
                                        ]}
                                    />
                                </Select>
                            </FormControl>

                            <TextField
                                className={classes.formControl}
                                value={devicePayload?.deviceName}
                                id="deviceName"
                                required
                                label={<CustomTransComponent translationKey={'DEVICE_FORM_COMMON:DEVICE_NAME'} />}
                                fullWidth
                                variant={'filled'}
                                onChange={payloadHandler}
                                sx={{ marginBottom: '0 !important' }}
                            />
                            <Typography variant="body2" className="custom-add-device-field-lengths-style">
                                {`${devicePayload?.deviceName?.length}/${DEVICE_FIELDS_LIMIT['deviceName']}`}
                            </Typography>

                            {!isLoadingUiMetadata && elements
                                ? elements.map(
                                      (field: any) =>
                                          (watch('communicationType') === field.show || !field.show) && (
                                              <Element key={field.id} field={field} control={control} />
                                          )
                                  )
                                : null}

                            <Divider
                                sx={{
                                    mb: 3,
                                    mx: 'auto',
                                }}
                            />

                            <AutoCompleteTags
                                error={isTagError}
                                value={devicePayload?.tagIds}
                                disabled={isDeviceTagFetching || isDeviceTagLoading}
                                onChange={selectedTagsHandler}
                                options={deviceTagsList?.data.records.map((device: any) => device.name) ?? []}
                                handleTagDelete={handleTagDelete}
                                onKeyDown={handleKeyDown}
                                placeholder={t('COMMON:TAGS')}
                            />
                            <Box className="modal-space-between">
                                <Typography
                                    variant="body2"
                                    sx={{ paddingLeft: '16px' }}
                                    className="custom-add-device-field-lengths-style"
                                >
                                    <CustomTransComponent
                                        translationKey={'DEVICE_FORM_COMMON:TAGS_LIMIT_TEXT'}
                                        replace={{ count: TAGS_LIMIT }}
                                    />
                                </Typography>
                                <Typography variant="body2" className="custom-add-device-field-lengths-style">
                                    {`${devicePayload?.tagIds?.length || 0}/${TAGS_LIMIT}`}
                                </Typography>
                            </Box>
                            <TextField
                                value={devicePayload?.description}
                                id="description"
                                label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                                fullWidth
                                variant={'filled'}
                                onChange={payloadHandler}
                                sx={{ marginBottom: '0 !important' }}
                            />
                            <Typography
                                variant="body2"
                                sx={{ marginBottom: '0 !important' }}
                                className="custom-add-device-field-lengths-style"
                            >
                                {`${devicePayload?.description?.length}/${DEVICE_FIELDS_LIMIT['description']}`}
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.cardFooter}>
                            {sm ? null : (
                                <Stack spacing={2} direction={'column'} sx={{ width: '100%' }}>
                                    <Button
                                        sx={{
                                            backgroundColor: theme?.palette?.primary?.main,
                                            '&:hover': {
                                                backgroundColor: theme?.palette?.primary?.main,
                                            },
                                            '&.Mui-disabled': {
                                                color: theme?.palette?.primary?.[200],
                                                backgroundColor: theme?.palette?.primary?.[50],
                                            },
                                        }}
                                        variant={'contained'}
                                        disabled={disableHandler()}
                                        onClick={(): void => {
                                            setConfirmationModal(true);
                                        }}
                                        data-test="save-device-btn"
                                    >
                                        <CustomTransComponent translationKey={'ADD_DEVICE_FORM:SAVE_DEVICE'} />
                                    </Button>
                                    <Button
                                        sx={{
                                            border: `1px solid ${theme?.palette?.primary?.main}`,
                                            '&:hover': {
                                                backgroundColor: theme?.palette?.primary?.[50],
                                                color: theme?.palette?.primary?.main,
                                            },
                                            '&.Mui-disabled': {
                                                border: `1px solid ${theme?.palette?.primary?.[200]}`,
                                                color: theme?.palette?.primary?.[200],
                                            },
                                        }}
                                        variant={'outlined'}
                                        onClick={handleDiscardChanges}
                                        data-test="cancel-btn"
                                    >
                                        <CustomTransComponent translationKey={'COMMON:CANCEL'} />
                                    </Button>
                                </Stack>
                            )}
                            {smDown ? null : (
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Button
                                            sx={{
                                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                                '&:hover': {
                                                    backgroundColor: theme?.palette?.primary?.[50],
                                                    color: theme?.palette?.primary?.main,
                                                },
                                                '&.Mui-disabled': {
                                                    border: `1px solid ${theme?.palette?.primary?.[200]}`,
                                                    color: theme?.palette?.primary?.[200],
                                                },
                                            }}
                                            variant={'outlined'}
                                            onClick={handleDiscardChanges}
                                            data-test="cancel-btn"
                                        >
                                            <CustomTransComponent translationKey={'COMMON:CANCEL'} />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                                            <Button
                                                sx={{
                                                    backgroundColor: theme?.palette?.primary?.main,
                                                    '&:hover': {
                                                        backgroundColor: theme?.palette?.primary?.main,
                                                    },
                                                    '&.Mui-disabled': {
                                                        color: theme?.palette?.primary?.[200],
                                                        backgroundColor: theme?.palette?.primary?.[50],
                                                    },
                                                }}
                                                variant={'contained'}
                                                disabled={disableHandler()}
                                                onClick={(): void => {
                                                    setConfirmationModal(true);
                                                }}
                                                data-test="save-device-btn"
                                            >
                                                <CustomTransComponent translationKey={'ADD_DEVICE_FORM:SAVE_DEVICE'} />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )}
                        </CardActions>
                    </Card>
                </Container>
                <DiscardModal
                    key="discardModal"
                    isVisible={discardChangesModal}
                    modalHandler={(action: boolean): void => setDiscardChangesModal(action)}
                />
                <CustomModal
                    key={'confirmationModal'}
                    isOpen={confirmationModal}
                    header={<CustomTransComponent translationKey={'DEVICE_MANAGEMENT:EDIT_CONFIRMATION_HEADER'} />}
                    type="primary"
                    actions={actions}
                >
                    <Typography variant="body1" fontSize={'16px'}>
                        <CustomTransComponent translationKey={'DEVICE_MANAGEMENT:EDIT_CONFIRMATION_TEXT'} />
                    </Typography>
                </CustomModal>
                {(isLoadingUiMetadata ||
                    isLoadingMasterData ||
                    isDeviceUpdated ||
                    isDeviceTagLoading ||
                    isTagCreating ||
                    isDeviceDataLoading ||
                    isTagsListLoading) && <BackdropLoader isOpen={true} />}
            </Box>
        </React.Fragment>
    );
};
