import React from 'react';

import { createNumArray } from 'utils/helpers';
import { getRequiredLabel } from '../common/utils/helpers';
import { Grid, Stack, Typography } from '@mui/material';

export const DayViewHeader = React.memo(
    (): JSX.Element => (
        <Grid container>
            {createNumArray(24)?.map((item, index) => (
                <Grid item xs={0.5} key={`wsw4${item}`}>
                    <Stack
                        style={{
                            height: 10,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'center',
                            margin: 0,
                        }}
                        className="border-right-1 border-bottom-1"
                    >
                        <Typography variant="body2" sx={{ mt: '-40px', ml: '-20px' }}>
                            {index % 3 === 0 ? `${getRequiredLabel(index).padStart(2, '0')}:00` : ''}
                        </Typography>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    )
);
