import React from 'react';
import {
    Box,
    Divider,
    Grid,
    Paper,
    Typography,
    Skeleton,
    Button,
    Stack,
    TextField,
    Container,
    FormHelperText,
} from '@mui/material';
import List from '@mui/material/List';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    useGetTimelineDetailsByIdQuery,
    useUpdateAcknowledgeMutation,
} from '@fiji/common/src/features/Timeline/timelineApi';
import SimpleCardSkeleton from '../SimpleCardSkeleton';
import { useTransformData } from 'hooks/useTransformData';
import {
    setMessageContent,
    setPageHeaderData,
    setTimelineStatusColor,
} from '@fiji/common/src/features/common/commonSlice';
import NotificationsOff from '@mui/icons-material/NotificationsOff';
import { CustomModal, ModalActions } from 'components/CustomModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { EventDetails } from './EventDetails';
import { DeviceDetails } from './DeviceDetails';
import moment from 'moment';
import { AlarmOccurred } from './AlarmOccurred';
import { STATUS_COLORS } from 'pages/WidgetManagement/common';

export const TimeLineDetails = (): JSX.Element => {
    const theme: any = useTheme();
    const { timeLineId } = useParams();
    const [acknowledgementComment, setAcknowledgementComment] = React.useState('');
    const [isRetry, setIsRetry] = React.useState(false);
    const [discard, setDiscard] = React.useState(false);
    const currentOrg = useTypedSelector(selectedOrg);

    const {
        currentData: data,
        isLoading,
        isFetching,
    }: any = useGetTimelineDetailsByIdQuery(timeLineId, { refetchOnMountOrArgChange: true });

    const dispatch = useAppDispatch();
    const { convertDateTime } = useTransformData();
    const [updateAcknowledge, { isLoading: isUpdating }] = useUpdateAcknowledgeMutation();

    const submitHandler = async (): Promise<void> => {
        const { error }: any = await updateAcknowledge({
            id: timeLineId,
            groupId: currentOrg?.id,
            deviceId: data?.data?.deviceId,
            acknowledgementComment,
        });
        if (error) {
            setIsRetry(true);
            onCancel();
        } else {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: 'Alarm Acknowledged Successfully',
                })
            );
            onCancel();
            setAcknowledgementComment('');
        }
    };
    const { isVisible, onClick, onCancel, onConfirm } = useConfirm(submitHandler);

    React.useEffect(() => {
        if (data) {
            dispatch(setPageHeaderData(data?.data?.deviceTimeline));

            dispatch(
                setTimelineStatusColor(
                    STATUS_COLORS[data?.data?.status === 'Active' ? data?.data?.severity : 'Informational'] ??
                        STATUS_COLORS?.Informational
                )
            );
            setAcknowledgementComment(data?.data?.acknowledgementComment);
        }
        return (): void => {
            dispatch(setTimelineStatusColor(''));
        };
    }, [data]);

    const AckActions: ModalActions[] = [
        {
            testId: 'cancel',
            key: 'cancel',
            label: 'Cancel',
            // close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
                if (acknowledgementComment?.length) setDiscard(true);
            },
        },
        {
            testId: 'acknowledge',
            key: 'save',
            label: 'Acknowledge',
            // close: true,
            disabled: !acknowledgementComment?.length,
            variant: 'contained',
            handleClick: onConfirm,
        },
    ];

    const DiscardActions: ModalActions[] = [
        {
            key: 'back',
            label: 'Back',
            // close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onClick();
                setDiscard(false);
            },
        },
        {
            key: 'discard',
            label: 'Discard',
            // close: true,
            variant: 'contained',
            className: 'error-btn',
            color: 'error',
            handleClick: (): void => {
                setAcknowledgementComment('');
                setDiscard(false);
            },
        },
    ];

    const renderAcknowledgementHeading = (): JSX.Element => {
        if (isLoading || isFetching) {
            return (
                <div style={{ padding: '6px' }}>
                    <Skeleton animation="wave">
                        <Typography variant="body1">Acknowledgement Details</Typography>
                    </Skeleton>
                </div>
            );
        }
        return (
            <Typography variant="body1" color="primary" sx={{ padding: '16px', fontSize: '14px', fontWeight: '600' }}>
                Acknowledgement Details
            </Typography>
        );
    };

    const renderAcknowledgementSubheading = (): JSX.Element => {
        if (isLoading || isFetching) {
            return (
                <div style={{ padding: '6px' }}>
                    <Skeleton animation="wave">
                        <Typography variant="body1">Description of device can be anything</Typography>
                    </Skeleton>
                </div>
            );
        }
        return (
            <Stack p={2} spacing={2}>
                <Typography variant="body1" className="black-500">
                    {data?.data?.acknowledgementComment ?? '-'}
                </Typography>
                <Typography variant="caption" className="color-content" fontSize={'14px'} fontWeight={'700'}>
                    {`Acknowledged by ${data?.data?.acknowledgedBy} ${moment(data?.data?.alarmUpdated ?? null).format(
                        'DD/MM/YYYY'
                    )} at ${moment(data?.data?.alarmUpdated ?? null).format('h:mm:a')}`}
                </Typography>
            </Stack>
        );
    };

    const renderDescriptionTitle = (): JSX.Element => {
        if (isLoading || isFetching) {
            return (
                <div style={{ padding: '6px' }}>
                    <Skeleton animation="wave">
                        <Typography variant="body1">Description</Typography>
                    </Skeleton>
                </div>
            );
        }
        return (
            <Stack p={2}>
                <Typography
                    variant="body1"
                    fontSize={'14px'}
                    fontWeight={'600'}
                    sx={{ color: theme?.palette?.primary?.main }}
                >
                    Description
                </Typography>
            </Stack>
        );
    };

    const renderDescription = (): JSX.Element => {
        if (isLoading || isFetching) {
            return (
                <div style={{ padding: '6px' }}>
                    <Skeleton animation="wave">
                        <Typography variant="body1">Description of device can be anything</Typography>
                    </Skeleton>
                </div>
            );
        }
        return (
            <Stack p={2}>
                <Typography variant="body1" fontSize={'14px'} className="black-500">
                    {data?.data?.description ?? '-'}
                </Typography>
            </Stack>
        );
    };

    return (
        <Container maxWidth="xl" className="padding-y-24">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {data?.data?.isAcknowledged ? (
                        <Paper>
                            {renderAcknowledgementHeading()}
                            <Divider />
                            {renderAcknowledgementSubheading()}
                        </Paper>
                    ) : (
                        data?.data &&
                        !data?.data?.isAcknowledged &&
                        data?.data?.severity === 'Alarm' && (
                            <Button
                                id="acknowledge"
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.main,
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.primary?.main,
                                    },
                                }}
                                variant="contained"
                                onClick={onClick}
                                startIcon={<NotificationsOff />}
                            >
                                Acknowledge
                            </Button>
                        )
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {renderDescriptionTitle()}
                        <Divider />
                        {renderDescription()}
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{ marginBottom: '16px' }}>
                        <EventDetails
                            isLoading={isFetching || isLoading}
                            data={data}
                            convertDateTime={convertDateTime}
                        />
                    </Paper>
                    {Boolean(Object.keys(data?.data?.occurredTrends ?? {})?.length) && (
                        <Paper>
                            <AlarmOccurred
                                isLoading={isFetching || isLoading}
                                data={data}
                                convertDateTime={convertDateTime}
                            />
                        </Paper>
                    )}
                </Grid>

                <Grid item xs={6}>
                    <Paper sx={{ marginBottom: '16px' }}>
                        <List disablePadding>
                            {isLoading || isFetching ? (
                                <SimpleCardSkeleton count={3} />
                            ) : (
                                <>
                                    <Stack p={2} className="border-bottom-1">
                                        <Typography
                                            sx={{ fontSize: '14px', color: theme?.palette?.primary?.main }}
                                            fontWeight={'600'}
                                        >
                                            History
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        className="border-bottom-1"
                                        p={2}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography className="text-secondary" fontSize={'15px'}>
                                            Last Update Received
                                        </Typography>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                                {data?.data?.alarmUpdated
                                                    ? convertDateTime({
                                                          timestamp: parseInt(data?.data?.alarmUpdated),
                                                      })
                                                    : '--'}
                                                ,
                                            </Typography>
                                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                                {data?.data?.alarmUpdated
                                                    ? convertDateTime({
                                                          timestamp: parseInt(data?.data?.alarmUpdated),
                                                          customFormat: 'HH:mm:ss',
                                                      })
                                                    : '--'}
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    {Boolean(data?.data?.alarmCleared) && (
                                        <>
                                            <Stack
                                                direction={'row'}
                                                className="border-bottom-1"
                                                p={2}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                            >
                                                <Typography className="text-secondary" fontSize={'15px'}>
                                                    Time Event Cleared
                                                </Typography>
                                                <Stack direction={'row'} alignItems={'center'}>
                                                    <Typography
                                                        fontWeight={'600'}
                                                        fontSize={'15px'}
                                                        className="text-secondary"
                                                    >
                                                        {convertDateTime({
                                                            timestamp: parseInt(data?.data?.alarmCleared),
                                                        }) || '-'}
                                                        ,
                                                    </Typography>
                                                    <Typography
                                                        fontWeight={'600'}
                                                        fontSize={'15px'}
                                                        className="text-secondary"
                                                    >
                                                        {convertDateTime({
                                                            timestamp: parseInt(data?.data?.alarmCleared),
                                                            customFormat: 'HH:mm:ss',
                                                        }) || '-'}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Divider />
                                        </>
                                    )}
                                    <Stack
                                        direction={'row'}
                                        className="border-bottom-1"
                                        p={2}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography className="text-secondary" fontSize={'15px'}>
                                            Time of Event
                                        </Typography>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                                {convertDateTime({
                                                    timestamp: parseInt(data?.data?.alarmOccurred),
                                                }) || '-'}
                                                ,
                                            </Typography>
                                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                                {convertDateTime({
                                                    timestamp: parseInt(data?.data?.alarmOccurred),
                                                    customFormat: 'HH:mm:ss',
                                                }) || ' - '}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </>
                            )}
                        </List>
                    </Paper>

                    <Paper>
                        <DeviceDetails isLoading={isFetching || isLoading} data={data} />
                    </Paper>
                </Grid>
            </Grid>
            <CustomModal
                key={'Acknowledges'}
                isOpen={isVisible}
                isLoading={isLoading || isUpdating}
                header="Acknowledge alarm"
                actions={AckActions}
                customHeader={
                    <Stack>
                        <Typography variant="h6" fontSize={'20px'} fontWeight={'600'}>
                            Acknowledge Alarm
                        </Typography>
                        <Stack mt={2}>
                            <Box>
                                <Typography variant="body1">
                                    Use this field to give context or add more information about the alarm, in order to
                                    acknowledge it.
                                </Typography>
                            </Box>
                            <Box mt={1} mb={4}>
                                <Typography variant="body1">
                                    Later on it will be possible to see all notes left by every operator on the
                                    dedicated alarm page.
                                </Typography>
                            </Box>
                        </Stack>
                    </Stack>
                }
                type="small"
            >
                <Stack>
                    <TextField
                        id="notes"
                        variant="filled"
                        label="Notes"
                        value={acknowledgementComment}
                        multiline
                        maxRows={15}
                        onChange={(e: any): void => {
                            if (e.target.value.length > 256) {
                                return;
                            }
                            setAcknowledgementComment(e?.target?.value ?? '');
                        }}
                        className="max-height-500 overflow-y-scroll"
                    />
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'end'} px={2}>
                        <FormHelperText>{acknowledgementComment?.length ?? 0}/256</FormHelperText>
                    </Stack>
                </Stack>
            </CustomModal>
            <CustomModal
                key={'retry'}
                isOpen={isRetry}
                isLoading={isLoading}
                actions={[
                    {
                        key: 'save',
                        label: 'Ok',
                        // close: true,
                        variant: 'contained',
                        handleClick: (): void => setIsRetry(false),
                    },
                ]}
                header="Please Retry"
                noDividers
                actionsDivider
                type="primary"
            >
                <Stack display="flex" flexDirection="column">
                    <Typography variant="body1">
                        {`Acknowledgment failed to communicate to end device at ${convertDateTime({
                            timestamp: Date.now(),
                        })}, ${convertDateTime({
                            timestamp: Date.now(),
                            customFormat: 'h:mm:ss',
                        })} ${convertDateTime({
                            timestamp: Date.now(),
                            customFormat: 'A',
                        })}.`}{' '}
                        If this problem continues, please call our support team at{' '}
                        <a href="tel:877-386-2273">877-386-2273</a> or email{' '}
                        <a href="mailto:BLsupport@eaton.com">BLsupport@eaton.com</a>.
                    </Typography>
                </Stack>
            </CustomModal>
            <CustomModal
                key={'retry'}
                isOpen={discard}
                isLoading={isLoading}
                actions={DiscardActions}
                header="Discard Changes?"
                noDividers
                actionsDivider
                type="primary"
            >
                <Stack display="flex" flexDirection="column">
                    <Typography variant="body1">This action cannot be undone.</Typography>
                </Stack>
            </CustomModal>
        </Container>
    );
};
