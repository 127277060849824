import React from 'react';
import { CustomTreeView } from './CustomTreeView';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'dnd-multi-backend';
import { getBackendOptions } from './utils';
import { Providers } from './providers';
import { useDropHandler } from './hooks';
import { DefaultPlaceholderRender } from './DefaultPlaceholderRender';
import { DefaultNodeRender } from './DefaultNodeRender';
import { HierarchyProps } from './types';

export const TreeViewProviders = (props: HierarchyProps): JSX.Element => {
    const getPlaceholderRender = (node: any, { depth }: any): JSX.Element => (
        <DefaultPlaceholderRender node={node} depth={depth} />
    );

    const args: any = {
        tree: props.data ? JSON.parse(JSON.stringify(props.data)) : [],
        classes: {
            draggingSource: {
                opacity: 0.3,
            },
            placeholder: {
                position: 'relative',
            },
        },
        dropTargetOffset: 10,
        render: function render(
            node: any,
            parentNode: any,
            options: any,
            mouseOverOptions: { isMouseOver: boolean; setIsMouseOver: any }
        ): JSX.Element {
            return (
                <DefaultNodeRender
                    labelColor={props?.labelColor}
                    node={node}
                    parentNode={parentNode}
                    {...options}
                    isMouseOver={mouseOverOptions?.isMouseOver}
                    setIsMouseOver={mouseOverOptions?.setIsMouseOver}
                />
            );
        },
        dragPreviewRender: props?.dragPreviewRender,
        onDrop: () => {}, // newTree, options
        canDrop: (tree: any, { dragSource, dropTargetId }: any) => {
            if (dragSource?.id !== dropTargetId) {
                return true;
            }
        },
        placeholderRender: getPlaceholderRender,
    };

    const [tree, handleDrop, setTree] = useDropHandler(args);
    const [expandedItems, setExpandedItems] = React.useState<string[]>([]);
    const [paginationMappingData, setPaginationMappingData] = React.useState<{ [key: string]: number }>({});

    React.useEffect(() => {
        if (JSON.stringify(tree) !== JSON.stringify(props?.data)) {
            setTree(props?.data);
        }
    }, [props?.data]);

    return (
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <Providers
                {...args}
                {...props}
                expandedItems={expandedItems}
                setExpandedItems={setExpandedItems}
                paginationMappingData={props?.paginationMappingData ?? paginationMappingData}
                setPaginationMappingData={props?.setPaginationMappingData ?? setPaginationMappingData}
                tree={tree}
                onDrop={handleDrop}
            >
                <CustomTreeView {...props} expandedItems={expandedItems} args={args} tree={tree} />
            </Providers>
        </DndProvider>
    );
};
