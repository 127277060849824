import React from 'react';

import { Component as DefaultComponent } from '../../common';

const Component = (props: any): JSX.Element => (
    <DefaultComponent
        {...(props?.widgetData?.primary === undefined
            ? { widgetData: { ...(props?.widgetData ?? {}), primary: [] } }
            : { ...(props ?? {}) })}
    />
);
export default Component;
