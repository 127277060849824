import React from 'react';

import CustomIcon from '../../../../components/CustomIcon';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import { useSortedData } from '../../../../hooks/useSortedData';
import { Device } from '@brightlayer-ui/icons-mui';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Avatar, Divider, Paper, Stack, Typography } from '@mui/material';
import { GroupPath } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common';
import { CustomEmptyState } from 'components/CustomEmptyState';

const Component = (props: any): JSX.Element => {
    const currentRealmName = useTypedSelector((state: any) => state.common.selectedRealm);

    const { sortedData, ids } = useSortedData(props?.widgetData ?? {}, ['secondary'], 'ASC');
    const navigate = useNavigate();

    const getLeftComponent = (component: any, isVisible: boolean): JSX.Element => (
        <Stack className="white-space-pre">
            <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>{component?.name}</Typography>
            {isVisible && (
                <GroupPath
                    key={component?.id}
                    deviceName={component?.deviceName}
                    pathName={component?.groupPath}
                    width={'220px'}
                />
            )}
            <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>{component?.age}</Typography>
        </Stack>
    );

    return (
        <Paper sx={{ height: '100%' }}>
            <WidgetHeader mode={props?.mode} sortedData={sortedData} widgetData={props?.widgetData} />
            {props?.mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${
                        props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'
                    })`,
                    overflow: 'auto',
                }}
            >
                {sortedData?.secondary?.length ? (
                    sortedData?.secondary
                        ?.slice(0, props?.widgetData.maxCount ?? props?.widgetData?.secondary?.length)
                        ?.map(
                            (component: any): JSX.Element => (
                                <InfoListItem
                                    key={component?.id}
                                    title={''}
                                    divider={'full'}
                                    iconAlign="center"
                                    statusColor={component?.indicator}
                                    leftComponent={getLeftComponent(component, props?.widgetData?.multiDevice)}
                                    icon={
                                        <Avatar sx={{ bgcolor: component?.status?.icon?.web?.style?.backgroundColor }}>
                                            <CustomIcon
                                                iconName={component?.icon?.web?.name ?? ''}
                                                family={component?.icon?.web?.family ?? ''}
                                                iconProps={{
                                                    sx: {
                                                        color: component?.icon?.web?.style?.color,
                                                    },
                                                }}
                                            />
                                        </Avatar>
                                    }
                                    chevron
                                    ripple
                                    onClick={(): void =>
                                        navigate(`/${currentRealmName}/device-components/${component?.id}`)
                                    }
                                />
                            )
                        )
                ) : (
                    <CustomEmptyState icon={<Device fontSize={'inherit'} />} title={'No component'} />
                )}
            </Stack>
            <WidgetFooter
                widgetData={props?.widgetData}
                ids={ids}
                mode={props?.mode}
                disabled={!sortedData?.secondary?.length}
            />
        </Paper>
    );
};

export default React.memo(Component);
