import React from 'react';

import {
    useGetAllChannelsQuery,
    useGetChannelDetailsMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { retrieveChannelTypefromWidgetType } from '@fiji/common/src/utils/helpers';

type Props = {
    sourceId: string;
    deviceId: string | undefined;
    widgetType: string;
    filter?: any;
    isFilter?: boolean;
};

type ReturnProps = {
    data: any;
    isLoading: boolean;
    isError: boolean;
    payload: any;
    payloadChangeHandler: any;
};

export const useGetDeviceDetails = ({ sourceId, deviceId, widgetType, filter, isFilter }: Props): ReturnProps => {
    //    {searchKey:'Unit',searchValue:['C'] }
    const {
        currentData: channels,
        isLoading: areChannelsLoading,
        isFetching,
        isError,
    } = useGetAllChannelsQuery(
        {
            sourceId: sourceId,
            params: {
                channelType: retrieveChannelTypefromWidgetType(widgetType),
                ...(isFilter && { body: { searchKey: 'Unit', searchValue: filter } }),
            },
        },
        { skip: !sourceId || !deviceId, refetchOnMountOrArgChange: true }
    );

    const [getChannelValues, { isLoading, data, isError: detailsError }] = useGetChannelDetailsMutation();

    const [details, setDetails] = React.useState<any>();

    React.useEffect(() => {
        if (channels?.data && deviceId) void configurePayload();
    }, [channels, deviceId]);

    React.useEffect(() => {
        if (data) {
            configureRequiredData(data);
        }
    }, [data]);

    const pushChannelsInWidgetData = (traversingData: any, widgetData: any, channel: any): void => {
        traversingData?.data?.forEach((detail: any) => {
            const selectedChannel = detail?.channels?.find((item: any) => item.channelId === channel?.channelId);
            widgetData?.channels.push({ ...channel, value: selectedChannel?.value });
        });
    };

    function configureRequiredData(traversingData: any): void {
        let widgetData: any = { deviceId: deviceId, channels: [] };
        if (channels?.data) {
            channels?.data?.forEach((channel: any) => {
                pushChannelsInWidgetData(traversingData, widgetData, channel);
            });
            setDetails({ secondary: [widgetData] });
        } else {
            setDetails({ secondary: traversingData?.data });
            widgetData = traversingData?.data;
        }
    }

    async function configurePayload(): Promise<void> {
        const payload = [{ deviceId: deviceId, channelId: channels?.data?.map((item: any) => item.channelId) }];

        await getChannelValues(payload);
    }

    async function payloadChangeHandler(payload: any): Promise<void> {
        await getChannelValues(payload);
    }

    return {
        data: details,
        isLoading: (isLoading || isFetching || areChannelsLoading) && !detailsError,
        isError: isError,
        payload: [{ deviceId: deviceId, channelId: channels?.data?.map?.((item: any) => item.channelId) }],
        payloadChangeHandler,
    };
};
