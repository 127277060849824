import { useEffect, useState } from 'react';

export type ChannelData = {
    deviceId?: string;
    devicePath?: string[];
    channelId?: string;
    channelName?: string;
    order: number;
    value?: any;
    unit?: string;
    icon: {
        mobile: {
            family: string;
            name: string;
        };
        web: {
            family: string;
            name: string;
        };
    };
    [key: string]: any;
};

export const useSortedData = (data: any, sortBy: string[], sortOrder?: string, targetedKey?: string): any => {
    const [sortedData, setSortedData] = useState({});
    const [ids, setIds] = useState<any>([]);

    const fetchData = (item: string): any => {
        const arr: ChannelData[] = [];

        data[item]?.forEach((device: any) => {
            if (device?.[targetedKey ?? 'channels']) {
                device?.[targetedKey ?? 'channels']?.forEach((channel: any) => {
                    arr.push({
                        deviceName: device?.deviceName,
                        deviceId: device?.deviceId ?? '',
                        devicePath: device?.devicePath ?? device?.groupPath ?? [],
                        ...channel,
                    });
                });
            } else {
                arr.push(device);
            }
        });

        return [...arr]?.sort((a, b) => (sortOrder === 'DESC' ? b?.order - a?.order : a?.order - b?.order));
    };

    const handleSetIds = (item: any): any => {
        setIds((prev: any) => [
            ...prev,
            ...data[item].map((dataItem: any) => ({
                deviceId: dataItem?.deviceId,
                devicePath: dataItem?.devicePath ?? dataItem?.groupPath ?? [],
                deviceName: dataItem?.deviceName ?? '',
            })),
        ]);
    };

    const generateSortedData = (item: any): void => {
        setSortedData((prev) => ({ ...prev, [item]: fetchData(item) }));
        if (data?.[item]?.length) {
            handleSetIds(item);
        }
    };

    useEffect(() => {
        if (data) {
            if (Object.keys(data ?? {})?.length) {
                Object.keys(data).forEach((item) => {
                    if (sortBy.includes(item)) {
                        generateSortedData(item);
                    } else {
                        setSortedData((prev) => ({ ...prev, [item]: data[item] }));
                    }
                });
            }
        }
        return () => setSortedData({});
    }, [sortOrder, data]);

    return {
        sortedData,
        ids: ids?.filter?.(
            (item: any, index: number) => ids?.findIndex((fItem: any) => fItem?.deviceId === item?.deviceId) === index
        ),
    };
};
