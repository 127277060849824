import React, { ReactNode } from 'react';
import { Spacer } from '@brightlayer-ui/react-components';
import { AppBar, Button, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTransComponent } from './CustomTransComponent';
/**
 * The above type defines the props for a custom app bar component in a TypeScript React application.
 * @property handleDiscard - A function that will be called when the discard button is clicked. It does
 * not take any arguments and does not return anything.
 * @property handleSubmit - A function that will be called when the primary button in the app bar is
 * clicked. It is responsible for handling the submission of the form or any other action that needs to
 * be performed.
 * @property {boolean} isSaveOrDiscardButtonDisabled - A boolean value indicating whether the save or
 * discard buttons should be disabled or not.
 * @property {string} primaryButtonText - The primaryButtonText property is an optional string that
 * represents the text to be displayed on the primary button in the custom app bar.
 * @property {string} secondaryButtonText - The `secondaryButtonText` property is an optional string
 * that represents the text to be displayed on the secondary button in the custom app bar.
 */
type CustomAppBarProps = {
    handleDiscard: () => void;
    handleSubmit: () => void;
    isSaveOrDiscardButtonDisabled: boolean;
    primaryButtonText?: ReactNode;
    secondaryButtonText?: ReactNode;
    customText?: ReactNode;
    hideActionButton?: boolean;
};

/**
 * The `CustomAppBar` component is a TypeScript React component that renders an AppBar with two
 * buttons, one for discarding changes and one for saving changes.
 * @param {CustomAppBarProps} props - The `props` parameter in the `CustomAppBar` component is an
 * object that contains the following properties:
 * @returns The CustomAppBar component is returning a JSX element, specifically an AppBar component
 * from a UI library. The AppBar component is styled with the "static" position and has a className of
 * "bg-white flex-row". Inside the AppBar, there are two Button components. The first Button is
 * disabled based on the value of the "isSaveOrDiscardButtonDisabled" prop and has an onClick event
 * handler assigned to
 */
export const CustomAppBar = (props: CustomAppBarProps): JSX.Element => {
    const theme: any = useTheme();
    const appbarRef: any = React.useRef();

    return (
        <AppBar
            ref={appbarRef}
            position="static"
            className="bg-white flex-row"
            sx={{
                '&.MuiPaper-root.MuiAppBar-root': {
                    zIndex: 900,
                    position: 'sticky',
                    left: 'auto',
                    right: 0,
                    height: '56px',
                },
            }}
        >
            {props.hideActionButton ? (
                <Stack direction={'row'} alignItems={'center'} p={2}>
                    <Typography variant="body1" align="left" fontWeight={400} className="text-black">
                        {props.customText}
                    </Typography>
                </Stack>
            ) : (
                <>
                    <Spacer />
                    <Button
                        id="discard"
                        disabled={props.primaryButtonText !== 'Cancel' && props.isSaveOrDiscardButtonDisabled}
                        onClick={props.handleDiscard}
                        className="margin-8"
                        sx={{
                            border: `1px solid ${theme?.palette?.primary?.main}`,
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.[50],
                            },
                        }}
                        variant="outlined"
                    >
                        {props.primaryButtonText ?? (
                            <CustomTransComponent
                                size={{ height: '5px', width: '100px' }}
                                translationKey={'COMMON:DISCARD_CHANGES'}
                            />
                        )}
                    </Button>
                    <Button
                        id="save"
                        disabled={props.isSaveOrDiscardButtonDisabled}
                        onClick={props.handleSubmit}
                        className="margin-8"
                        sx={{
                            backgroundColor: theme?.palette?.primary?.main,
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.main,
                            },
                            '&.Mui-disabled': {
                                color: theme?.palette?.primary?.[200],
                                backgroundColor: theme?.palette?.primary?.[50],
                            },
                        }}
                        variant="contained"
                    >
                        {props.secondaryButtonText ?? (
                            <CustomTransComponent
                                size={{ height: '5px', width: '100px' }}
                                translationKey={'COMMON:SAVE_CHANGES'}
                            />
                        )}
                    </Button>
                </>
            )}
        </AppBar>
    );
};
