import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Breadcrumbs, Chip, FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useLazyGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { AutoCompleteTags } from 'pages/AddDevice';
import { useTheme } from '@mui/material/styles';
import {
    useCreateTagMutation,
    useGetTagsQuery,
    useUpdateMultipleTagsMutation,
} from '@fiji/common/src/features/TagManagement/tagManagementApi';
import { setDeviceSideBar, setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { isNullOrUndefined } from '@fiji/common/src/utils/helpers';
import { EditDevicesSkeleton } from './EditDevicesSkeleton';

type Props = {
    onEditDeviceCancel: () => void;
    selectedDevices: any;
};

const sumArray = (arr: any): any => {
    if (arr.length === 0) return 0;
    const [first, ...rest] = arr;
    return first?.tags?.length + sumArray(rest);
};

export const EditDeviceDrawer = ({ onEditDeviceCancel, selectedDevices }: Props): JSX.Element => {
    const theme: any = useTheme();
    const { sideBarOpen: deviceSideBar } = useTypedSelector((state) => state?.common.deviceSideBar);

    const dispatch = useAppDispatch();
    const [deviceDetails, setDeviceDetails] = React.useState<any>([]);
    const [devicePayload, setDevicePayload] = React.useState<any>([]);
    const [payload, setPayload] = React.useState<any>([]);

    const [createTag, { isLoading: isTagCreating }] = useCreateTagMutation();

    const [getDeviceDetails, { isLoading: deviceDetailLoader, isFetching: deviceDetailsFetching }] =
        useLazyGetDeviceByIdQuery();
    const chipClassHandler = (tagId: any, deviceId: any): boolean => {
        if (
            payload
                ?.find((payloadDevice: any) => deviceId === payloadDevice?.deviceId)
                ?.tags.findIndex((tag: any) => tag === tagId) === -1
        ) {
            return true;
        }
        return false;
    };
    const {
        data: deviceTagsList,
        isLoading: isDeviceTagLoading,
        isFetching: isDeviceTagFetching,
    } = useGetTagsQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
    }>({
        page: 0,
        size: 100,
    });
    const [updateDevicesTags] = useUpdateMultipleTagsMutation();

    function payloadHandler(tagId: string, deviceId: string): void {
        const payloadClone = JSON.parse(JSON.stringify(payload));
        const selectedDeviceIndex = payloadClone?.findIndex((item: any) => item.deviceId === deviceId);
        const selectedTagIndex = payloadClone?.[selectedDeviceIndex]?.tags?.indexOf(tagId);

        if (!isNullOrUndefined(selectedTagIndex) && selectedTagIndex !== -1) {
            payloadClone[selectedDeviceIndex]?.tags?.splice(selectedTagIndex, 1);
        }

        setPayload(payloadClone);
    }

    const handleSetTags = (): any => {
        let newPayload: any = JSON?.parse(JSON?.stringify(payload));
        devicePayload?.forEach((item: any) => {
            newPayload = newPayload?.map((prev: any) => {
                if (prev?.tags?.length > 5 || prev?.tags?.includes(item?.id)) {
                    return prev;
                }
                return {
                    ...prev,
                    tags: [...(prev?.tags ?? []), item?.id],
                };
            });
        });
        return newPayload;
    };

    const handleSave = async (): Promise<void> => {
        const { error }: any = await updateDevicesTags(handleSetTags());
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: 'Changes Saved Successfully',
                })
            );
            dispatch(
                setDeviceSideBar({
                    sideBarOpen: false,
                    isEnable: false,
                })
            );
            setDevicePayload([]);
            setDeviceDetails([]);
            setPayload([]);
            onEditDeviceCancel();
        }
    };

    const selectedTagsHandler = (e: any, values: any): void => {
        if (values?.length >= 20) {
            return;
        }
        setDevicePayload(values);
    };
    const handleTagDelete = (data: any, index: number): void => {
        const devicePayloadClone = JSON.parse(JSON.stringify(devicePayload));
        devicePayloadClone.splice(index, 1);

        setDevicePayload(devicePayloadClone);
    };

    let isEnteredTagValue = false;
    const handleKeyDown = async (e: any): Promise<void> => {
        if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
            isEnteredTagValue = true;
        } else if (e.code !== 'Enter' && e.code !== 'ArrowDown' && e.code !== 'ArrowUp') {
            isEnteredTagValue = false;
        }
        if (!isEnteredTagValue && e.code === 'Enter' && devicePayload && devicePayload?.length < 20) {
            const tagValue = e.target.value;
            const selectedTags = deviceTagsList?.data?.records?.find((item: any) => item?.name === tagValue);
            if (selectedTags) {
                setDevicePayload([...(devicePayload ?? []), selectedTags]);
                return;
            }
            const response: any = await createTag({
                name: tagValue,
            });

            if (!response?.error) {
                setDevicePayload([
                    ...(devicePayload ?? []),
                    { id: response?.data?.data?.id, name: response?.data?.data?.name },
                ]);
            }
        }
    };

    const renderTagChips = (device: any): any =>
        device?.tags?.map((label: any) => (
            <Chip
                key={label?.id}
                deleteIcon={chipClassHandler(label?.id, device?.id) ? <Add /> : <Close />}
                label={label?.name}
                disabled={chipClassHandler(label?.id, device?.id)}
                onDelete={(): void => {
                    payloadHandler(label?.id, device?.id);
                }}
            />
        ));

    const maxedOutDevice = (): JSX.Element => {
        if (deviceDetailLoader || deviceDetailsFetching) {
            return <EditDevicesSkeleton />;
        }
        return deviceDetails?.map?.((device: any) => {
            if (device?.tags.length > 5) {
                return (
                    <>
                        <Stack mt={2}>
                            <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                {device?.name ?? '--'}
                            </Typography>
                            <Typography variant="body2" fontSize={'14px'}>
                                {device?.serialNumber ?? '--'}
                            </Typography>
                            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                                {(device?.groupPath ?? []).map(
                                    (breadcrumb: any): JSX.Element =>
                                        breadcrumb && (
                                            <Typography key="3" color="text.primary">
                                                {breadcrumb ?? '--'}
                                            </Typography>
                                        )
                                )}
                            </Breadcrumbs>
                        </Stack>
                        <Stack direction={'row'} gap={1} mt={2} flexWrap={'wrap'} pr={2}>
                            {renderTagChips(device)}
                        </Stack>
                    </>
                );
            }
            return <></>;
        });
    };
    const tagHandler = async (id: string): Promise<void> => {
        if (deviceDetails?.findIndex((item: any) => item?.id === id) === -1) {
            const response = await getDeviceDetails(id);
            if (response?.isSuccess) {
                setDeviceDetails((prev: any) => [...prev, response?.data?.data]);
            }
        }
    };

    React.useEffect(() => {
        if (deviceSideBar) {
            selectedDevices?.forEach((selectedDevice: string) => {
                void tagHandler(selectedDevice);
            });
        }
    }, [selectedDevices, deviceSideBar]);

    const getDeviceDetailsPayload = (): any =>
        deviceDetails?.map((item: any) => ({
            deviceId: item?.id,
            tags: item?.tags?.map?.((subItem: any) => subItem?.id),
        }));

    React.useEffect(() => {
        if (deviceDetails.length === selectedDevices?.length) {
            setPayload(getDeviceDetailsPayload);
        }
    }, [deviceDetails]);

    React.useEffect(() => {
        if (deviceDetails.length > selectedDevices?.length) {
            const deviceClone = JSON.parse(JSON.stringify(deviceDetails));
            deviceClone?.forEach((item: any, index: number) => {
                if (!selectedDevices?.includes(item?.id)) {
                    deviceClone.splice(index, 1);
                }
            });
            setDeviceDetails(deviceClone);
        }
    }, [selectedDevices, deviceDetails]);

    return (
        <React.Fragment key={'right'}>
            <Drawer
                variant="persistent"
                anchor={'right'}
                open={deviceSideBar}
                onClose={onEditDeviceCancel}
                className="custom-drawer"
            >
                <Stack justifyContent={'space-between'} className="width-368 h-100">
                    <Stack>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className="border-bottom-1"
                            p={2}
                        >
                            <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'} color="primary">
                                Edit Device(s)
                            </Typography>
                            <IconButton
                                onClick={(): void => {
                                    setDeviceDetails([]);
                                    onEditDeviceCancel();
                                    setPayload([]);
                                }}
                            >
                                <Close className="black-500" />
                            </IconButton>
                        </Stack>
                        <Stack py={3} px={2}>
                            <AutoCompleteTags
                                value={devicePayload}
                                disabled={Boolean(isDeviceTagFetching) || Boolean(isDeviceTagLoading) || isTagCreating}
                                onChange={selectedTagsHandler}
                                options={
                                    deviceTagsList?.data?.records
                                        ?.filter(
                                            (item: any) =>
                                                devicePayload?.findIndex((subItem: any) => subItem?.id === item?.id) ===
                                                -1
                                        )
                                        .map((device: any) => ({
                                            name: device?.name,
                                            id: device?.id,
                                        })) ?? []
                                }
                                handleTagDelete={handleTagDelete}
                                onKeyDown={handleKeyDown}
                                placeholder="Add Tag(s)"
                            />
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} px={2}>
                                <FormHelperText>Max of 6 tags on each device</FormHelperText>
                                <FormHelperText>{0}/16</FormHelperText>
                            </Stack>
                            {deviceDetails?.filter((item: any) => item?.tags?.length > 5)?.length > 1 && (
                                <Stack py={3}>
                                    <Typography>{`${
                                        deviceDetails?.filter((item: any) => item?.tags?.length > 5)?.length
                                    } Device with maximum tags reached`}</Typography>
                                </Stack>
                            )}
                            <Stack py={3}>{selectedDevices?.length > 1 && maxedOutDevice()}</Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        p={2}
                        className="border-top-1"
                    >
                        <Button
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                },
                            }}
                            variant="outlined"
                            onClick={(): void => {
                                setPayload([]);
                                setDeviceDetails([]);
                                onEditDeviceCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={
                                deviceDetailsFetching ||
                                deviceDetailLoader ||
                                (!devicePayload?.length && sumArray(payload) === sumArray(deviceDetails)) ||
                                isTagCreating
                            }
                            sx={{
                                backgroundColor: theme?.palette?.primary?.main,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.main,
                                },
                            }}
                            onClick={handleSave}
                            variant="contained"
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
        </React.Fragment>
    );
};
