import React from 'react';

import { createNumArray } from 'utils/helpers';
import { Grid, Stack, Typography } from '@mui/material';

export const TimeSlotGrid = (): JSX.Element => (
    <Grid container direction="column">
        <Grid item xs={0.5}>
            <Stack
                style={{
                    height: 40,
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'center',
                    margin: 0,
                }}
                className="border-right-1"
            />
        </Grid>
        {createNumArray(24).map((item, index) => (
            <Grid item xs={0.5} key={item}>
                <Stack
                    style={{
                        height: 60,
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'center',
                        margin: 0,
                    }}
                    className="border-right-1"
                >
                    <Typography variant="body2" mb={7.5}>{`${String(index).padStart(2, '0')}:00`}</Typography>
                </Stack>
            </Grid>
        ))}
    </Grid>
);
