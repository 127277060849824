import React from 'react';

import CustomIcon from '../../../../components/CustomIcon';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import { useSortedData } from '../../../../hooks/useSortedData';
import { InfoListItem } from '@brightlayer-ui/react-components';
import HelpCenter from '@mui/icons-material/HelpCenter';
import { Avatar, Divider, Paper, Stack, Typography } from '@mui/material';
import { CustomTransComponent, GroupPath } from 'components';
import { Update } from '@mui/icons-material';
import { CustomEmptyState } from 'components/CustomEmptyState';

const Component = (props: any): JSX.Element => {
    const { sortedData, ids } = useSortedData(props?.widgetData ?? {}, ['secondary'], 'ASC', 'upcoming');

    const getDays = (fTimestamp: number): number => {
        const getRemainingTimeInMs = fTimestamp - Date.now();
        return Math?.floor(getRemainingTimeInMs / 1000 / 60 / 60 / 24);
    };

    const getTimePrefix = (days: any): string => {
        if (days > 1 && days < 365) {
            return 'Soon';
        } else if (days > 1 && days >= 365) {
            return 'Within';
        }
        return 'Now';
    };

    const getLeftComponent = (upcoming: any): JSX.Element => {
        const days = getDays(upcoming?.withing ?? Date.now());

        return (
            <Stack className="white-space-pre">
                <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>
                    {getTimePrefix(days)}
                </Typography>

                {(days > 1 || days < 365) && (
                    <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#424E54' }}>
                        {`${days} days`}
                    </Typography>
                )}
            </Stack>
        );
    };

    return (
        <Paper sx={{ height: '100%' }}>
            <WidgetHeader mode={props?.mode} sortedData={sortedData} widgetData={props?.widgetData} />
            {props?.mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${
                        props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'
                    })`,
                    overflow: 'auto',
                }}
            >
                {sortedData?.secondary?.length ? (
                    sortedData?.secondary?.map(
                        (upcoming: any): JSX.Element => (
                            <InfoListItem
                                key={upcoming?.id}
                                title={upcoming?.type}
                                {...(props?.widgetData?.multiDevice && {
                                    subtitle: [
                                        <GroupPath
                                            key={upcoming?.id}
                                            deviceName={upcoming?.deviceName}
                                            pathName={upcoming?.groupPath}
                                            width={'220px'}
                                        />,
                                    ],
                                })}
                                divider={'full'}
                                iconAlign="center"
                                statusColor={upcoming?.indicator}
                                leftComponent={getLeftComponent(upcoming)}
                                icon={
                                    <Avatar sx={{ bgcolor: upcoming?.status?.icon?.web?.style?.backgroundColor }}>
                                        <CustomIcon
                                            iconName={upcoming?.status?.icon?.web?.name ?? ''}
                                            family={upcoming?.status?.icon?.web?.family ?? ''}
                                            iconProps={{
                                                sx: {
                                                    color: upcoming?.status?.icon?.web?.style?.color,
                                                },
                                            }}
                                            defaultIcon={<HelpCenter color="primary" />}
                                        />
                                    </Avatar>
                                }
                                chevron
                                ripple
                            />
                        )
                    )
                ) : (
                    <CustomEmptyState
                        icon={<Update fontSize={'inherit'} />}
                        title={<CustomTransComponent translationKey={'No Upcoming'} />}
                    />
                )}
            </Stack>
            <WidgetFooter
                widgetData={props?.widgetData}
                ids={ids}
                mode={props?.mode}
                disabled={!sortedData?.secondary?.length}
            />
        </Paper>
    );
};

export default Component;
