import React, { ReactNode } from 'react';

import dayjs from 'dayjs';
import { useTypedSelector } from '@fiji/common';
import { Grid, Stack, Typography } from '@mui/material';
import { CurrentDateTimeIndicator, DayViewHeader, DeviceTypeRow, DeviceRow } from './';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Schedule } from '@mui/icons-material';

export const DayView = React.memo(
    ({ gridLabel, leftGrid }: { gridLabel: ReactNode; leftGrid?: JSX.Element }): JSX.Element => {
        const schedules = useTypedSelector((state) => state['schedule']['schedules']);
        const selectedDate = useTypedSelector((state: any) => state['schedule']['selectedDate']);
        const isGroupingEnabled = useTypedSelector((state) => state['schedule']['isGroupingEnabled']);

        const getDayViewContent = (): any => {
            if (!schedules?.length) {
                return (
                    <EmptyState
                        sx={{ position: 'relative', left: '-203px' }}
                        icon={<Schedule fontSize="inherit" />}
                        title="No Scheduled Actions"
                        description="Choose “Add Schedule” to Schedule actions."
                    />
                );
            } else if (isGroupingEnabled) {
                return <DeviceTypeRow />;
            }
            return <DeviceRow />;
        };

        return (
            <div style={{ position: 'relative', paddingTop: 72, overflow: 'auto' }} className="margin-top-36">
                <Grid container>
                    <Grid item xs={2}>
                        <Stack
                            style={{
                                height: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 0,
                            }}
                            className="border-right-1 border-bottom-1 padding-left-16"
                        >
                            <Typography variant="body2" style={{ fontWeight: 'bold' }} pb={'20px'}>
                                {gridLabel ?? 'Device'}
                            </Typography>
                        </Stack>
                        {leftGrid}
                    </Grid>
                    <Grid item xs={10}>
                        <Stack direction="row">
                            {dayjs()?.isSame(selectedDate, 'day') && <CurrentDateTimeIndicator />}
                            <Stack width="100%">
                                <DayViewHeader />
                                {getDayViewContent()}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        );
    }
);
