import { Typography } from '@mui/material';
import React from 'react';

export const LocationText = (props: any): JSX.Element => {
    const [location, setLocation] = React.useState('-');

    React.useEffect(() => {
        if (props?.data?.key === 'location') {
            if (props?.data?.value?.lat && props?.data?.value?.lng) {
                const geocoder = new google.maps.Geocoder();
                geocoder
                    .geocode({ location: props?.data?.value })
                    .then((response) => {
                        setLocation(response.results[0]?.formatted_address);
                    })
                    .catch(() => {
                        console.error('Error fetching location details');
                    });
            }
        }
    }, [props.data]);
    return <Typography sx={{ wordBreak: 'break-word', maxWidth: '650px' }}>{location}</Typography>;
};
