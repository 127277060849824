import React from 'react';
import * as MUIIcons from '@mui/icons-material';
import * as BLUIIcons from '@brightlayer-ui/icons-mui';
import { Box } from '@mui/material';
type CustomIconProps = {
    family: string;
    iconName: string;
    iconProps?: any;
    defaultIcon?: any;
};

const CustomIcon = (props: CustomIconProps): JSX.Element => {
    const getIconFamily = (family: string): any => {
        if (family === 'blui') {
            return BLUIIcons;
        }
        return MUIIcons;
    };

    const getDefaultIcon = (): any => {
        if (props?.family === 'loads') {
            return <Box sx={{ ...props.iconProps?.sx }}>{props.iconProps?.loadNumber}</Box>;
        } else if (getIconFamily(props.family)?.[props.iconName]) {
            return React.createElement(getIconFamily(props.family)[props.iconName], { ...props?.iconProps });
        }
        return props?.defaultIcon ?? <MUIIcons.HelpCenter {...props?.iconProps} />;
    };

    return <Box>{getDefaultIcon()}</Box>;
};

export default React.memo(CustomIcon);
