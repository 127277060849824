import React from 'react';

import { BackdropLoader, CustomModal, CustomTransComponent, DeleteModal } from '../../../components';
import { useConfirm } from '@fiji/common/src/hooks';
import { Icon, List, Radio, Stack, TextField, Typography } from '@mui/material';
import { ModalActions } from '../../../components/CustomModal';
import { InfoListItem } from '@brightlayer-ui/react-components';
import {
    useDeleteDashboardMutation,
    useLazyGetAllDashboardsQuery,
    useUpdateDashboardMutation,
} from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { useDispatch } from 'react-redux';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import Link from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import { useDebounce, useIsMount } from 'hooks';

export const DeleteDashboardModal = React.forwardRef((props: any, ref): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMount = useIsMount();
    const [selectedDashboard, setSelectedDashboard] = React.useState<any>({});
    const [activeModal, setActiveModal] = React.useState('');
    const [selectedData, setSelectedData] = React.useState<any>(null);
    const [deleteDashboard, { isLoading: isDeletingDashboard }] = useDeleteDashboardMutation();
    const [updateDashboard, { isLoading: isUpdatingDashboard }] = useUpdateDashboardMutation();
    const [searchKey, setSearchKey] = React.useState('');
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const closeModal = (): void => {
        setSearchKey('');
        setActiveModal('');
    };

    const [getFilter, { data, isLoading, isFetching }]: any = useLazyGetAllDashboardsQuery();

    React.useEffect(() => {
        if (!isMount && activeModal === 'Assign') {
            getFilter({
                body: {
                    page: 0,
                    size: 10,
                    ...(!isMount && { searchKey: debouncedValue }),
                    filters: {
                        status: ['INACTIVE'],
                        ...(selectedData?.assignType === 'GROUP'
                            ? { groupId: selectedData?.assignTypeIds[0] }
                            : { deviceId: selectedData?.assignTypeIds[0] }),
                    },
                },
            });
        }
    }, [debouncedValue]);

    const deleteModal = async (dashboardData: any): Promise<any> => {
        const { data: newData } = await getFilter({
            body: {
                page: 0,
                size: 10,
                filters: {
                    status: ['INACTIVE'],
                    ...(dashboardData?.assignType === 'GROUP' && { groupId: dashboardData?.assignTypeIds[0] }),
                    ...(dashboardData?.assignType === 'DEVICE' && { deviceId: dashboardData?.assignTypeIds[0] }),
                    ...(dashboardData?.assignType === 'SOURCE' && { sourceId: dashboardData?.sourceId }),
                },
            },
        });

        if (newData) {
            if (newData?.data?.records?.length && dashboardData.status !== 'INACTIVE') {
                setActiveModal('Assign');
            } else if (dashboardData.status !== 'INACTIVE' && !newData?.data?.records?.length) {
                setActiveModal('EmptyModal');
            } else if (dashboardData.status === 'INACTIVE') {
                setActiveModal('Delete');
            }
        }
    };

    const openModal = (dashboardData: any): void => {
        setSelectedData(dashboardData);
        deleteModal(dashboardData) as any;
    };
    React.useImperativeHandle(ref, () => ({
        handleModalAction: (dashboardData: any): void => openModal(dashboardData),
    }));

    const updateDashboardHandler = async (): Promise<void> => {
        const { error }: any = await updateDashboard({
            body: {
                status: 'ACTIVE',
                assignType: selectedData?.assignType,
                assignTypeIds: selectedData?.assignTypeIds,
            },
            params: { dashboardId: selectedDashboard?.id ?? '' },
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('DASHBOARDS:DASHBOARD_UPDATE_SUCCESS'),
                    duration: 3000,
                })
            );
            setActiveModal('Delete');
        }
    };

    const deleteModalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: closeModal,
        },
        {
            key: 'next',
            label: <CustomTransComponent translationKey={'COMMON:NEXT'} />,
            variant: 'contained',
            disabled: !selectedDashboard?.id,
            handleClick: updateDashboardHandler as any,
        },
    ];
    const noModalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CLOSE'} />,
            close: true,
            variant: 'outlined',
            handleClick: closeModal,
        },
    ];

    React.useEffect(() => {}, [searchKey]);

    const deleteHandler = async (): Promise<void> => {
        const { error }: any = await deleteDashboard({ id: selectedData?.id ?? '' });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('DASHBOARDS:DASHBOARD_DELETE_SUCCESS', {
                        replace: {
                            name: selectedData?.name,
                        },
                    }),
                    duration: 3000,
                })
            );
            closeDeleteModal();
            closeModal();
            setSelectedDashboard({});
            setSelectedData(null);
        }
    };

    const getSelectedNode = (item: any): any => {
        if (item?.assignType === 'DEVICE') {
            return 'Device';
        } else if (item?.assignType === 'SOURCE') {
            return 'Source';
        }
        return 'Group';
    };

    const { onCancel: closeDeleteModal, onConfirm: confirmDeletion } = useConfirm(deleteHandler as any);
    return (
        <>
            {(isLoading || isFetching || isDeletingDashboard || isUpdatingDashboard) && (
                <BackdropLoader isOpen={true} />
            )}
            <CustomModal
                isOpen={activeModal === 'Assign'}
                type="primary"
                actions={deleteModalActions}
                customHeader={
                    <>
                        <Stack>
                            <Typography variant="body1" fontSize={'20px'} fontWeight={'600'} mb={2}>
                                <CustomTransComponent
                                    translationKey={'DASHBOARDS:DELETE_DASHBOARD.DELETE_DASHBOARD_TEXT'}
                                />
                            </Typography>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="body1" fontWeight={'400'} mt={2}>
                                <CustomTransComponent
                                    translationKey={'DASHBOARDS:DELETE_DASHBOARD.DELETE_DEFAULT_DASHBOARD_DESC'}
                                    replace={{
                                        dataSource: selectedData?.sourceName ?? selectedData?.assignType,
                                    }}
                                />
                            </Typography>
                        </Stack>
                    </>
                }
                actionsDivider
            >
                <List className="height-200 overflow-y-scroll">
                    <Stack
                        direction={'row'}
                        spacing={2}
                        my={2}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                    >
                        <TextField
                            sx={{ width: '100%' }}
                            hiddenLabel
                            id="searchKey"
                            placeholder="Search"
                            size="small"
                            onChange={(e: any): void => {
                                setSearchKey(e.target.value);
                            }}
                            value={searchKey || ''}
                            InputProps={{
                                startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                            }}
                        />
                    </Stack>

                    {data?.data?.records?.map((item: any) => (
                        <InfoListItem
                            key={item?.id}
                            divider="full"
                            hidePadding
                            title={getSelectedNode(item)}
                            subtitle={item?.sourceName ?? item?.assignType}
                            leftComponent={
                                <Stack mr={3} minWidth={'170px'}>
                                    <Typography variant="body2" fontSize={'14px'} fontWeight={'600'}>
                                        {item?.name}
                                    </Typography>
                                    <Typography variant="body2" fontSize={'14px'} fontWeight={'400'}>
                                        {item?.isSystemDashboard ? 'System' : 'User'}
                                    </Typography>
                                </Stack>
                            }
                            // subtitle={getAssignment(data, assignment)}
                            rightComponent={
                                <>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography variant="body2" fontWeight={'400'} fontSize={'14px'} mr={1}>
                                            {item?.associatedCount}
                                        </Typography>
                                        <Icon>
                                            <Link></Link>
                                        </Icon>
                                    </Stack>
                                    <Stack ml={2}>
                                        <Radio
                                            checked={item?.id === selectedDashboard?.id}
                                            onChange={(): void => setSelectedDashboard(item)}
                                            value={item?.id}
                                            id={item?.name}
                                            name="radio-buttons"
                                        />
                                    </Stack>
                                </>
                            }
                            classes={{ title: 'minWidth' }}
                        />
                    ))}
                </List>
            </CustomModal>
            <CustomModal
                isOpen={activeModal === 'EmptyModal'}
                type="primary"
                actions={noModalActions}
                customHeader={
                    <>
                        <Stack>
                            <Typography variant="body1" fontSize={'20px'} fontWeight={'600'} mb={2}>
                                <CustomTransComponent translationKey={'DASHBOARDS:DELETE_DASHBOARD.UNABLE_DELETE'} />
                            </Typography>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="body1" fontWeight={'400'} mt={2}>
                                <CustomTransComponent
                                    translationKey={'DASHBOARDS:DELETE_DASHBOARD.UNABLE_DELETE_TEXT'}
                                />
                            </Typography>
                        </Stack>
                    </>
                }
                noDividers
                actionsDivider
            >
                <></>
            </CustomModal>
            <DeleteModal
                onCancel={(): void => {
                    closeDeleteModal();
                    closeModal();
                }}
                confirmClick={confirmDeletion}
                isVisible={activeModal === 'Delete'}
                header={<CustomTransComponent translationKey={'DASHBOARDS:DELETE_DASHBOARD.DELETE_DASHBOARD_TEXT'} />}
                noDividers
                description={
                    <CustomTransComponent
                        translationKey={'DASHBOARDS:DELETE_DASHBOARD.DELETE_DASHBOARD_DESCRIPTION'}
                        replace={{
                            name: selectedData?.name,
                        }}
                    />
                }
                deleteButtonText={'DASHBOARDS:DELETE_DASHBOARD.DELETE_DASHBOARD_TEXT'}
                isLoading={isDeletingDashboard}
            />
        </>
    );
});
