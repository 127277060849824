import { EmptyState } from '@brightlayer-ui/react-components';
import { Typography } from '@mui/material';
import React from 'react';

export const CustomEmptyState = ({ title, description, ...props }: any): JSX.Element => (
    <EmptyState
        {...props}
        title={(<Typography variant="h6">{title}</Typography>) as any}
        description={<Typography variant="subtitle2">{description}</Typography>}
    />
);
