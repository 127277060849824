import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Stack } from '@mui/material';
import { Spacer } from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import { CustomTransComponent, HeaderActions } from './';
import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useTranslation } from 'react-i18next';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { setAppHeaderTitle } from '@fiji/common/src/features/common/commonSlice';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

/* The code is defining and exporting a React functional component called `AppHeader`. It takes in a
single prop called `props` which is an object with two properties: `title` (a string) and `page` (a
JSX element). */
export const AppHeader = (props: {
    title: string;
    subTitle?: string;
    page: JSX.Element;
    isSystemAdmin?: boolean;
}): JSX.Element => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state) => state?.common?.selectedRealm);
    const currentOrg = useTypedSelector(selectedOrg);
    const headerTitle = useTypedSelector((state: RootState) => state?.common?.appHeaderTitle);
    const headerSubTitle = useTypedSelector((state) => state.common.appHeaderSubTitle);
    const { t } = useTranslation();
    const isAdminRealm = useTypedSelector((state: RootState) => state.common.isAdminRealm);
    const isSuperRealm = useTypedSelector((state: RootState) => state.common.isSuperRealm);

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
            }}
            minHeight={'100vh'}
            position={'relative'}
        >
            <AppBar className="position-sticky" sx={{ pr: `0px !important` }}>
                <Toolbar className="padding-x-16 min-height-56">
                    {isSuperRealm && window?.location?.pathname !== `/${currentRealmName}` && (
                        <IconButton
                            className="margin-right-32"
                            onClick={(): void => {
                                navigate(`/${currentRealmName}`);
                                dispatch(setAppHeaderTitle(undefined));
                            }}
                        >
                            <ArrowBack className="text-white width-24 height-24" />
                        </IconButton>
                    )}
                    {isAdminRealm ? (
                        <Stack direction={'column'} spacing={0}>
                            <Typography variant={'h6'} color={'inherit'} lineHeight={'23px'}>
                                {headerTitle ?? t(props?.title)}
                            </Typography>
                            <Typography variant={'subtitle2'} color={'inherit'} lineHeight={'23px'}>
                                {headerSubTitle ?? t(props?.subTitle ?? '')}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack direction={'column'} spacing={0}>
                            <Typography variant={'h6'} color={'inherit'} lineHeight={'23px'}>
                                {headerTitle ?? <CustomTransComponent translationKey={props?.title} />}
                            </Typography>
                            {!headerTitle && !headerSubTitle && !(props?.subTitle ?? headerSubTitle) && (
                                <Typography variant={'subtitle2'} color={'inherit'} lineHeight={'23px'}>
                                    {currentOrg?.name ?? ''}
                                </Typography>
                            )}
                            {!headerTitle && (props?.subTitle || headerSubTitle) && (
                                <Typography variant={'subtitle2'} color={'inherit'} lineHeight={'23px'}>
                                    {headerSubTitle ?? <CustomTransComponent translationKey={props?.subTitle} />}
                                </Typography>
                            )}
                        </Stack>
                    )}
                    <Spacer />
                    <HeaderActions isSystemAdmin={props.isSystemAdmin} />
                </Toolbar>
            </AppBar>
            {props.page}
        </Box>
    );
};
